// App.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { NavBar, Logo, DemoButton, ButtonWrapper } from "./components/NavBar";
import {
  HeroSection,
  HeroHeading,
  HeroSubHeading,
} from "./components/HeroSection";
import {
  FeatureItem,
  FeatureText,
  FeaturesHeading,
  FeaturesImageDesktop,
  FeaturesImageMobile,
  FeaturesSection,
  FeaturesText,
} from "./components/FeaturesSection";
import {
  InfoHeading,
  InfoSection,
  InfoSubHeading,
} from "./components/InfoSection";
import {
  Card,
  CardHeading,
  CardSection,
  CardText,
} from "./components/CardSection";
import {
  CTABtn,
  CTAHeading,
  CTAInfo,
  CTASection,
  CTASubHeading,
} from "./components/CTASection";
import {
  Footer,
  FooterLogo,
  FooterLinks,
  FooterLink,
} from "./components/FooterSection";
import logo from "../assets/checkit-white.png";
import HangerBG from "./components/HangerBG";
import features from "./assets/features.png";
import {
  AiOutlineQrcode,
  AiOutlineShop,
  AiOutlineUsergroupAdd,
  AiOutlineApi,
} from "react-icons/ai";
import { PiHandWaving } from "react-icons/pi";
import { MdComputer } from "react-icons/md";

const Website = () => {
  const navigate = useNavigate();
  return (
    <div style={{ overflowY: "hidden" }}>
      <NavBar>
        <Logo src={logo} alt="Brand Logo" />
        <ButtonWrapper href="mailto::chasebergman@rogers.com">
          <DemoButton>Book a Demo</DemoButton>
        </ButtonWrapper>
      </NavBar>

      <HeroSection>
        <HeroHeading>Simplify nights out.</HeroHeading>
        <HeroSubHeading>
          Checkit offers a digital coat check solution
        </HeroSubHeading>
        <HeroSubHeading>from check-in to check-out.</HeroSubHeading>
        <HangerBG />
      </HeroSection>

      <FeaturesSection>
        <FeaturesImageDesktop src={features} alt="Feature Image" />
        <FeaturesText>
          <FeaturesHeading>Cut the wait time.</FeaturesHeading>
          <FeatureItem>
            <AiOutlineQrcode size={40} color="#184730" />
            <FeatureText>
              When they arrive, guests scan a QR code and receive an automated
              SMS confirmation, their digital ticket for the night.{" "}
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <PiHandWaving size={40} color="#184730" />
            <FeatureText>
              When its time to leave, guests can request their coat from
              anywhere in the venue, finish their drinks and say their goodbyes.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <AiOutlineShop size={40} color="#184730" />
            <FeatureText>
              When they reach the coat check, a simple exchange and their on
              their way. No long lines. No time wasted.{" "}
            </FeatureText>
          </FeatureItem>
        </FeaturesText>
        <FeaturesImageMobile src={features} alt="Feature Image" />
      </FeaturesSection>

      <InfoSection>
        <InfoHeading>Eliminate the hassles.</InfoHeading>
        <InfoSubHeading>
          Optimized check-in and check-out, effortless request fulfillment,
          rapid guest authentication: checkit is designed to make coat check an
          afterthought, for you and your guests.
        </InfoSubHeading>
      </InfoSection>

      <CardSection>
        <Card right={"25%"}>
          <MdComputer size={120} color="#fff" />
          <CardHeading>Checkit Suite</CardHeading>
          <CardText>
            Platform access for guests and hosts and customized tags for easy
            hanger identification.
          </CardText>
        </Card>
        <Card right={"50%"}>
          <AiOutlineUsergroupAdd size={120} color="#fff" />
          <CardHeading>Training & Consulting</CardHeading>
          <CardText>
            We want to ensure the product fits your business needs and that
            you're well equipped to use it.
          </CardText>
        </Card>
        <Card right={"75%"}>
          <AiOutlineApi size={120} color="#fff" />
          <CardHeading>Service & Support</CardHeading>
          <CardText>
            We want you to see the benefits we create and we’ll be there every
            step of the way to see that through.
          </CardText>
        </Card>
      </CardSection>

      <CTASection>
        <CTAInfo>
          <CTAHeading>Check it out.</CTAHeading>
          <CTASubHeading>
            See how checkit can solve your coat check problems and enhance your
            venue experience.
          </CTASubHeading>
        </CTAInfo>
        <a href="mailto::chasebergman@rogers.com">
          <CTABtn>Book a Demo</CTABtn>
        </a>
      </CTASection>

      <Footer>
        <FooterLogo src={logo} alt="Footer Logo" />
        <FooterLinks>
          <FooterLink onClick={() => navigate("/termsofuse#")}>
            Terms of Use
          </FooterLink>
          <FooterLink onClick={() => navigate("/privacypolicy#")}>
            Privacy Policy
          </FooterLink>
        </FooterLinks>
      </Footer>
    </div>
  );
};

export default Website;
