// frontend/src/components/Sidebar.js
import React, { useState } from "react";
import styled from "styled-components";
import {
  AiOutlineDashboard,
  AiOutlineFileText,
  AiOutlineTag,
  AiOutlineUser,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import FullLogo from "../assets/checkit-new-full.png";
import SmallLogo from "../assets/checkit-new-small.png";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import BaseModal from "./common/BaseModal";
import { createSession } from "../utils/api/api-consumer";
import { useUser } from "../context/UserContext";
import AlertBanner from "./common/AlertBanner";

const SidebarContainer = styled.div`
  width: 60px;
  height: 100vh;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10001;
  box-sizing: border-box;
  transition: margin-right 0.3s ease;

  &:hover {
    width: 200px;
    align-items: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const AnotherLogoContainer = styled.div`
  min-height: 70px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SmallLogoImage = styled.img`
  width: 30px; /* Adjust size as needed */
  height: auto;

  ${SidebarContainer}:hover & {
    display: none; /* Hide in expanded version */
  }
`;

const FullLogoImage = styled.img`
  width: 80%; /* Adjust size as needed */
  height: auto;
  display: none; /* Hidden in slim version */

  ${SidebarContainer}:hover & {
    display: block; /* Show in expanded version */
  }
`;

const NewSessionButton = styled.button`
  background-color: #184730;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 5px;
  transition: margin-right 0.3s ease;
  display: none;
  width: 80%;
  height: 30px;

  ${SidebarContainer}:hover & {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 5px);
  }
`;

const SlimNewSessionButton = styled.button`
  background-color: #184730;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: margin-right 0.3s ease;
  opacity: 1; /* Show in slim version */
  width: 30px;
  height: 30px;
  margin-bottom: 20px;

  display: flex; /* Show in expanded version */
  justify-content: center;
  align-items: center;

  ${SidebarContainer}:hover & {
    display: none; /* Show in expanded version */
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
`;

const MenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  color: ${(props) => (props.isActive ? "#184730" : "#656565")};
  cursor: pointer;
  position: relative;
  min-height: 39px;
  box-sizing: border-box;
  border-radius: 4px;

  ${(props) =>
    props.isActive &&
    `
    background-color: #E8F0EB;
  `}

  &:hover {
    color: #184730;
  }
`;

const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.2s ease;
  border-radius: 4px;

  svg {
    font-size: 16px;
  }
`;

const MenuText = styled.span`
  display: none;
  transition: margin-right 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping */
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;

  ${SidebarContainer}:hover & {
    display: block; /* Show text on hover */
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
  align-items: center;
`;

const UserInfoSquare = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex; /* Use flex to center content */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  color: #626262;
`;

const UserInfo = styled.div`
  text-align: left;
  transition: margin-right 0.3s ease;
  display: none;
  margin-left: 10px;
  color: #626262;
  ${SidebarContainer}:hover & {
    display: block; /* Show in expanded version */
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
`;

const Sidebar = () => {
  const location = useLocation();
  const [isNewSessionOpen, setIsNewSessionOpen] = useState(false);
  const { barId, userId } = useUser();
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const [showAlert, setShowAlert] = useState(false); // State for alert visibility
  const [alertType, setAlertType] = useState(false);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const navigate = useNavigate();

  const handleNewSession = async (name) => {
    if (!name) {
      console.log("No name provided");
      setAlertMessage("Must name session");
      setShowAlert(true);
      setAlertType("error");
      return;
    }

    if (!barId) {
      setAlertMessage("Something went wrong");
      setShowAlert(true);
      setAlertType("error");
      console.log("No barId, cannot create session.");
      return;
    }

    const response = await createSession(barId, name);
    if (response === "Session in progress") {
      setAlertMessage("Existing session in progress");
      setShowAlert(true);
      setAlertType("error");
      return;
    }

    if (!response?.sessionId) {
      console.log("Error when creating session.");
      setAlertMessage("Error creating session");
      setShowAlert(true);
      setAlertType("error");
      return;
    }

    navigate(`/session/?sessionId=${response.sessionId}`);
  };

  return (
    <>
      {isNewSessionOpen && (
        <BaseModal
          onClose={setIsNewSessionOpen}
          type="input"
          proceedType="Start"
          inputText="Name"
          suggestedInput={new Date().toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
          title="Start Session"
          proceedMethod={handleNewSession}
        />
      )}
      {showAlert && (
        <AlertBanner
          message={alertMessage}
          type={alertType}
          duration={2000}
          onClose={() => setShowAlert(false)}
        />
      )}
      <SidebarContainer>
        <AnotherLogoContainer>
          <LogoContainer>
            <SmallLogoImage src={SmallLogo} alt="Small Logo" />
            <FullLogoImage src={FullLogo} alt="Full Logo" />
          </LogoContainer>
        </AnotherLogoContainer>
        <NewSessionButton onClick={() => setIsNewSessionOpen(true)}>
          <AiOutlinePlusCircle style={{ marginRight: "10px" }} /> New Session
        </NewSessionButton>
        <SlimNewSessionButton>
          <AiOutlinePlusCircle />
        </SlimNewSessionButton>
        <StyledLink to="/dashboard">
          <MenuItem isActive={isActive("/dashboard")}>
            <MenuIcon isActive={isActive("/dashboard")}>
              <AiOutlineDashboard />
            </MenuIcon>
            <MenuText>Dashboard</MenuText>
          </MenuItem>
        </StyledLink>

        <StyledLink to="/sessions">
          <MenuItem isActive={isActive("/sessions")}>
            <MenuIcon isActive={isActive("/sessions")}>
              <AiOutlineFileText />
            </MenuIcon>
            <MenuText>Sessions</MenuText>
          </MenuItem>
        </StyledLink>

        <StyledLink to="/tags">
          <MenuItem isActive={isActive("/tags")}>
            <MenuIcon isActive={isActive("/tags")}>
              <AiOutlineTag />
            </MenuIcon>
            <MenuText>Tags</MenuText>
          </MenuItem>
        </StyledLink>
        <Divider />

        <StyledLink to="/roles">
          <MenuItem isActive={isActive("/roles")}>
            <MenuIcon isActive={isActive("/roles")}>
              <AiOutlineUser />
            </MenuIcon>
            <MenuText>Roles</MenuText>
          </MenuItem>
        </StyledLink>

        <UserInfoContainer>
          <UserInfoSquare>{userId?.barName[0].toUpperCase()}</UserInfoSquare>
          <UserInfo>
            <div style={{ fontSize: "12px" }}>{userId?.barName}</div>
            <div style={{ fontSize: "10px", marginTop: "3px" }}>
              {userId?.username}
            </div>
          </UserInfo>
        </UserInfoContainer>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
