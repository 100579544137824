import React from "react";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";
import { AiFillWarning } from "react-icons/ai";

const AlertContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 12px 24px 12px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  animation: slideDown 0.3s ease-out;

  /* Different styles based on type */
  background-color: #fff;

  @keyframes slideDown {
    from {
      transform: translate(-50%, -100%);
      opacity: 0;
    }
    to {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const AlertBanner = ({
  message,
  type = "info", // 'success' | 'error' | 'warning' | 'info'
  onClose,
  duration = 5000, // auto-close after 5 seconds, set to null to disable
}) => {
  React.useEffect(() => {
    if (duration && onClose) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  return (
    <AlertContainer type={type}>
      <CloseButton onClick={onClose}>
        {type === "success" ? (
          <AiFillCheckCircle style={{ color: "#184730" }} size={18} />
        ) : type === "warning" ? (
          <AiFillWarning style={{ color: "#ffcc00" }} size={18} />
        ) : (
          <AiFillCloseCircle style={{ color: "#CA4040" }} size={18} />
        )}
      </CloseButton>
      <Message>{message}</Message>
    </AlertContainer>
  );
};

export default AlertBanner;
