import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import logo from "../assets/checkit-white.png";
import { createNewSession } from "../utils/api/api-consumer";
import { useNavigate } from "react-router";
import Modal from "./ConfirmModal";
import { AiOutlineRightCircle } from "react-icons/ai";
import InstructionsModal from "./InstructionsModal";

const Container = styled.div`
  height: 100vh;
  background-color: #184730;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Jost;
`;

const SuccessTitle = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
`;

const SuccessSubHeading = styled.div`
  width: 60%;
  font-size: 14px;
  color: white;
  font-family: Jost;
`;

const FailMessage = styled.div`
  font-size: 14px;
  color: white;
  font-family: Jost;
  margin-top: 15px;
  width: 70%;
`;

const Logo = styled.img`
  width: 50%;
  max-width: 200px; /* Set a maximum width for the logo */
  margin-bottom: 120px;
  margin-top: 70px;
`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 10px;
  color: white;
  font-family: Jost;
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PhoneNumberInput = styled.input`
  width: 50%; /* Set a maximum width for the input */
  height: 20px;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  margin: 20px 10px;
  font-family: Jost;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const SubmitButton = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #184730;
  font-size: 14px;
  cursor: pointer;
  padding: 3px 12px 3px 15px;
  display: flex;
  font-family: Jost;
  justify-content: center;
  align-items: center;
`;

const ArrowContainer = styled.div`
  padding-left: 7px;
  padding-top: 4px;
`;

const Footer = styled.div`
  color: #fff;
  padding: 0px;
  text-align: center;
  position: fixed;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const FooterItems = styled.div`
  width: 100%;
  font-family: Jost;
  font-size: 10px;
`;

const FooterSpan = styled.span`
  cursor: pointer;
  color: #ccc;
`;

const SubmitPhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [success, setSuccess] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableTyping, setDisableTyping] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const navigate = useNavigate();

  useEffect(() => {
    if (!query.get("id")) {
      setFailMessage(
        "Something went wrong... Please try navigating from the provided link again."
      );
      setDisableTyping(true);
    }
  }, [query]);

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 4) return number;
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1 - $2");
    if (number.length < 11)
      return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1 - $2 - $3");
    return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1 - $2 - $3");
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = phoneNumberAutoFormat(e.target.value); // Remove non-numeric characters
    setPhoneNumber(inputValue.substring(0, 16)); // Limit to 10 characters
    if (inputValue.length === 16) {
      setDisableSubmit(false);
    } else {
      if (!disableSubmit) {
        setDisableSubmit(true);
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await createNewSession({
      hangerId: `${query.get("id")}-${phoneNumber.substring(
        phoneNumber.length - 4
      )}`,
      phoneNumber: phoneNumber,
      status: "coatChecked",
    });

    if (response === "success") {
      setSuccess(true);
    } else {
      setFailMessage("Something went wrong... Please try again");
    }
    setIsLoading(false);
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Logo src={logo} alt="Logo" />

      {success ? (
        <SuccessContainer>
          <SuccessTitle>Success!</SuccessTitle>
          <SuccessSubHeading>
            Check your text messages for a link to request your coat.
          </SuccessSubHeading>
        </SuccessContainer>
      ) : (
        <>
          <Title>Submit your phone number</Title>
          <PhoneNumberContainer>
            <PhoneNumberInput
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength="16"
              autoFocus
              disabled={disableTyping}
            />
          </PhoneNumberContainer>
          <ButtonContainer>
            <SubmitButton disabled={disableSubmit} onClick={() => openModal()}>
              Submit
              <ArrowContainer>
                <AiOutlineRightCircle />
              </ArrowContainer>
            </SubmitButton>
          </ButtonContainer>
          <ButtonContainer>
            {failMessage ? <FailMessage>{failMessage}</FailMessage> : null}
          </ButtonContainer>
        </>
      )}

      <Footer>
        <FooterItems>
          By submitting, I agree to CheckIts's{" "}
          <FooterSpan onClick={() => navigate("/termsofuse")}>
            Terms of Use
          </FooterSpan>{" "}
          and{" "}
          <FooterSpan onClick={() => navigate("/privacypolicy")}>
            Privacy Policy
          </FooterSpan>
        </FooterItems>
      </Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleSubmit}
        isLoading={isLoading}
      >
        <p>Confirm your phone number:</p>
        <h2>{phoneNumber}</h2>
      </Modal>
      {isInstructionsOpen ? (
        <InstructionsModal onClose={() => setIsInstructionsOpen(false)} />
      ) : null}
    </Container>
  );
};

export default SubmitPhoneNumber;
