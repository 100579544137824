export const processCoatData = (sessionInfo) => {
  const halfHourlyData = {};

  // Helper function to convert 24h to 12h format
  const formatTime = (hour, min) => {
    const period = hour >= 12 ? "PM" : "AM";
    const displayHour = hour > 12 ? hour - 12 : hour;
    return `${displayHour}:${min === 0 ? "00" : min}${period}`;
  };

  // Initialize half-hours from 18:00 to 23:30
  for (let hour = 18; hour <= 23; hour++) {
    for (let min = 0; min <= 30; min += 30) {
      const timeKey = `${hour}:${min === 0 ? "00" : "30"}`;
      halfHourlyData[timeKey] = {
        hour: formatTime(hour, min), // Format the display time
        checkIns: 0,
        checkOuts: 0,
      };
    }
  }
  // Count check-ins and check-outs for each half hour
  sessionInfo.forEach((coat) => {
    // Parse ISO datetime strings
    const arrivalDate = new Date(coat.checked_at);
    const departureDate = new Date(coat.request_fulfilled_at);

    // Extract hours and minutes
    const arrivalHour = arrivalDate.getHours();
    const arrivalMin = arrivalDate.getMinutes();
    const departureHour = departureDate.getHours();
    const departureMin = departureDate.getMinutes();

    // Round to nearest half hour
    const arrivalHalfHour = arrivalMin < 30 ? "00" : "30";
    const departureHalfHour = departureMin < 30 ? "00" : "30";

    const arrivalKey = `${arrivalHour}:${arrivalHalfHour}`;
    const departureKey = `${departureHour}:${departureHalfHour}`;

    if (halfHourlyData[arrivalKey]) halfHourlyData[arrivalKey].checkIns += 1;
    if (halfHourlyData[departureKey])
      halfHourlyData[departureKey].checkOuts += 1;
  });

  return Object.values(halfHourlyData);
};

export const calculateAverageDuration = (sessionInfo) => {
  // Filter out coats that haven't been picked up yet
  const completedSessions = sessionInfo.filter(
    (coat) => coat.request_fulfilled_at !== null
  );

  if (completedSessions.length === 0) return "0:00";

  const durations = completedSessions.map((coat) => {
    // Create Date objects from ISO strings
    const arrivalDate = new Date(coat.checked_at);
    const departureDate = new Date(coat.request_fulfilled_at);

    let arrivalMinutes = arrivalDate.getHours() * 60 + arrivalDate.getMinutes();
    let departureMinutes =
      departureDate.getHours() * 60 + departureDate.getMinutes();

    // Handle cases where departure is after midnight
    if (departureMinutes < arrivalMinutes) {
      departureMinutes += 24 * 60; // Add 24 hours
    }

    return departureMinutes - arrivalMinutes;
  });

  // Calculate average duration in minutes
  const averageMinutes =
    durations.reduce((a, b) => a + b, 0) / durations.length;

  // Convert to hours and minutes
  const hours = Math.floor(averageMinutes / 60);
  const minutes = Math.round(averageMinutes % 60);

  // Format as "H:MM"
  return `${hours}h:${minutes.toString().padStart(2, "0")}`;
};

// Add this new utility function
export const formatDateTime = (isoString) => {
  if (!isoString) return "-";

  const date = new Date(isoString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Convert 0 to 12

  return `${hours}:${minutes.toString().padStart(2, "0")}${ampm}`;
};
