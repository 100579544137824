// src/components/NewSubmit.js
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import logo from "../assets/checkit-new-small.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { requestCoat } from "../utils/api/api-consumer";
import ConfirmRequestModal from "./ConfirmRequestModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  background-color: #fafafa;
  text-align: center;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10vh;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin: 50px 0 0px;
  color: #515151;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 70%;
  padding: 10px 20px;
  background-color: #184730; /* Adjust color as needed */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin: 50px 0px 20px;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "none" : "#245b3a"}; /* Darker shade for hover */
    cursor: ${(props) =>
      props.disabled ? "not-allowed" : "pointer"}; /* Darker shade for hover */
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 25px; /* Adjusted to be slightly off the bottom */
  width: 100%;
  font-size: 8px;
  color: #999;
  z-index: 1000;
`;

const NewRequest = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [didCheck, setDidCheck] = useState(null);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const handleSubmit = async () => {
    setIsLoading(true);
    const id = query.get("id");

    try {
      await requestCoat(id);
      setDidCheck(true);
    } catch (err) {
      setDidCheck(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = "hidden";

    // Cleanup function to re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Container>
      {isModalOpen && (
        <ConfirmRequestModal
          onClose={() => setIsModalOpen(false)}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          didCheck={didCheck}
        />
      )}
      <Logo src={logo} alt="logo" />
      <Title>Ready to leave?</Title>
      <Subtitle>
        Request for your coat to be prepared for <br />
        pick-up by selecting the button below.
      </Subtitle>
      <Button onClick={() => setIsModalOpen(true)} disabled={!query.get("id")}>
        <ButtonContent>
          Request
          <AiOutlineArrowRight />
        </ButtonContent>
      </Button>
      {!query.get("id") && (
        <div
          style={{
            color: "#515151",
            marginTop: "30px",
            fontSize: "12px",
            width: "70vw",
          }}
        >
          Please try navigating from the QR code again.
        </div>
      )}
      <Footer>
        By submitting, I agree to checkit's Terms of Use and Privacy Policy
      </Footer>
    </Container>
  );
};

export default NewRequest;
