// src/components/TagFilter.js
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { NoDataContainer } from "./common/common-components";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineTags,
} from "react-icons/ai";

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 8px;
  cursor: pointer;
  width: 100px;
  text-align: left;
  color: #a1a1a1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

const DropdownContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  background-color: #fff;
  white-space: nowrap; // Prevent wrapping
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1002;
  width: 100%;
  max-height: 200px;
  min-width: 200px;
  overflow-y: auto;
  top: 115%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`;

const TagItem = styled.label`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
  color: #fff;
`;

const CircleIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 10px;
`;

const TagDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
`;

const DropdownIcon = styled.span`
  margin-left: 10px;
  font-size: 12px;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
`;

const TagFilter = ({ selectedItem, setSelectedItem, tags }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleTagChange = (tag) => {
    setSelectedItem((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        Tags {selectedItem.length > 0 && `(${selectedItem.length})`}
        <DropdownIcon>
          {isOpen ? (
            <AiOutlineCaretUp />
          ) : (
            <AiOutlineCaretDown style={{ marginTop: "1px" }} />
          )}
        </DropdownIcon>
      </DropdownButton>
      <DropdownContent isOpen={isOpen}>
        {tags?.length ? (
          tags.map((tag) => (
            <TagItem key={tag.label}>
              <Checkbox
                type="checkbox"
                checked={selectedItem.includes(tag.label)}
                onChange={() => handleTagChange(tag.label)}
              />
              <TagDetails>
                <span style={{ fontSize: "14px", color: "#515151" }}>
                  {tag.label}
                </span>
                <CircleIndicator
                  style={{ backgroundColor: tag.color }}
                ></CircleIndicator>
              </TagDetails>
            </TagItem>
          ))
        ) : (
          <NoDataContainer>
            <AiOutlineTags />
            No Tags
          </NoDataContainer>
        )}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default TagFilter;
