import React, { useState, useEffect, useRef } from "react";
import {
  PageContainer,
  Header,
  Divider,
  ButtonGroup,
  Button,
  InsideButton,
  Spinner,
} from "./common/common-components";
import { AiOutlinePlusCircle, AiOutlineRight } from "react-icons/ai";
import styled from "styled-components";
import TagFilter from "./TagFilter";
import TagData from "./TagData";
import DatePicker from "./DatePicker";
import BaseModal from "./common/BaseModal";
import { createSession, getSessions, getTags } from "../utils/api/api-consumer";
import { useNavigate } from "react-router";
import { useUser } from "../context/UserContext";
import * as moment from "moment";
import { GrCoatCheck } from "react-icons/gr";
import AlertBanner from "./common/AlertBanner";

const SessionTableContainer = styled.div`
  background-color: #fff;
  width: 60vw;
  height: calc(
    100vh - 150px
  ); // Adjust this value based on your header/footer height
  border-radius: 10px;
  border: 1px solid #ccc;
  margin: auto;
  // overflow: auto;
`;

const FullTableHeader = styled.div`
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  z-index: 100;
`;

const TopTableHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  background-color: #fff;
  z-index: 1000;
  border-radius: 10px;
`;

const Title = styled.h3`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
`;

const RightHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
`;

const TableContainer = styled.div`
  max-height: calc(100% - ${(props) => props.headerHeight}px);
  overflow-y: scroll;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #515151;
  max-height: 80%;
`;

const TableRow = styled.tr`
  border-top: 1px solid #eee;
  position: relative;
`;

const TableHeaderContainer = styled.div`
  width: 100%;
  box-shadow: inset 0 1px 0 #ccc, inset 0 -1px 0 #ccc; // Create inner top and bottom borders
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // position: sticky; // Make the table header sticky
  // top: ${(props) => props.headerHeight}px; // Use dynamic height
`;

const TableHeader = styled.div`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  width: 100px; // Set a fixed width for the header
`;

const TableData = styled.td`
  padding: 20px 10px;
  font-size: 14px;
  width: 100px; // Set a fixed width for the data cells
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: #a1a1a1;
  border: none;
  cursor: pointer;

  &:hover {
    color: #515151; // Use dynamic height
    transition: ease-in-out 0.3s;
  }
`;

const ClearFiltersButton = styled.div`
  background-color: white;
  color: #a1a1a1;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 10px;

  &:hover {
    color: #515151;
    transition: ease-in-out 0.3s;
  }
`;

const NoDataTableContainer = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  color: #a1a1a1;
  font-size: 14px;
`;

const LoadingContainer = styled.div`
  position: fixed; // Make the container fixed to the viewport
  top: 0; // Align to the top
  left: 0; // Align to the left
  height: 100vh; // Fill the entire viewport height
  width: calc(100vw); // Fill the entire viewport width minus the sidebar width
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; // Ensure it's above other elements
  background-color: #f9f9f9;
`;

const Sessions = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [sessionSelectedTags, setSessionSelectedTags] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isNewSessionOpen, setIsNewSessionOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [headerHeight, setHeaderHeight] = useState(0); // State to store header height
  const [sessions, setSessions] = useState([]); // Add state for sessions
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const [showAlert, setShowAlert] = useState(false); // State for alert visibility
  const [alertType, setAlertType] = useState(false); // State for alert visibility
  const { barId } = useUser();
  // const barId = "186898";
  const [isLoading, setIsLoading] = useState(true); // Add this state

  const panelHeaderRef = useRef(null); // Create a ref for the PanelHeader

  const handleClearFilters = () => {
    setSelectedTags([]);
    setStartDate(null);
    setEndDate(null);
  };

  const navigate = useNavigate();

  const handleNewSession = async (name) => {
    if (!name) {
      console.log("No name provided");
      return;
    }

    if (!barId) {
      console.log("No barId, cannot create session.");
      return;
    }
    const response = await createSession(barId, name);
    if (response === "Session in progress") {
      setAlertMessage("Existing session in progress");
      setShowAlert(true);
      setAlertType("error");
      return;
    }
    if (!response?.sessionId) {
      console.log("Error when creating session.");
      setAlertMessage("Error creating session");
      setShowAlert(true);
      setAlertType("error");
      return;
    }

    navigate(`/session?sessionId=${response.sessionId}`);
  };

  const handleJoinSession = async (sessionId) => {
    if (!sessionId) {
      console.log("No sessionId provided");
      return;
    }

    navigate(`/session?sessionId=${sessionId}`);
  };

  const handleViewInsights = async (sessionId, sessionName) => {
    navigate(`/session-info?sessionId=${sessionId}`, {
      state: {
        sessionName: sessionName,
      },
    });
  };

  useEffect(() => {
    const fetchSessions = async () => {
      if (!barId) {
        console.log("No barId, cannot get sessions.");
        return;
      }
      try {
        const allSessions = await getSessions(barId);
        setSessions(allSessions);
        if (!allSessions) {
          return;
        }

        const tagsBySession = {};
        for (const session of allSessions) {
          if (!(session.id in tagsBySession)) {
            tagsBySession[session.id] = [];
          }
          if (session.tags) {
            tagsBySession[session.id].push(...session.tags);
          }
        }

        setSessionSelectedTags(tagsBySession);
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    };

    const fetchTags = async () => {
      if (!barId) {
        console.log("No barId, cannot get tags.");
        return;
      }
      try {
        const allTags = await getTags(barId);
        setTags(allTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchSessions(), fetchTags()]);
      setIsLoading(false);
    };

    fetchData();
  }, [barId]);

  useEffect(() => {
    if (panelHeaderRef.current) {
      setHeaderHeight(panelHeaderRef.current.offsetHeight); // Set height based on ref
    }
  }, [panelHeaderRef]);

  return (
    <PageContainer>
      {isLoading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : null}
      {showAlert && (
        <AlertBanner
          message={alertMessage}
          type={alertType}
          duration={2000}
          onClose={() => setShowAlert(false)}
        />
      )}
      {isNewSessionOpen && (
        <BaseModal
          onClose={setIsNewSessionOpen}
          type="input"
          proceedType="Start"
          inputText="Name"
          suggestedInput={new Date().toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
          title="Start Session"
          proceedMethod={handleNewSession}
        />
      )}
      <Header width={60}>
        <span style={{ color: "#656565" }}>Sessions</span>
        <ButtonGroup>
          <Button primary onClick={() => setIsNewSessionOpen(true)}>
            <InsideButton>
              <AiOutlinePlusCircle style={{ marginRight: "8px" }} />
              New Session
            </InsideButton>
          </Button>
        </ButtonGroup>
      </Header>

      <Divider width={60} />

      <SessionTableContainer>
        <FullTableHeader ref={panelHeaderRef}>
          <TopTableHeader>
            <Title>{sessions.length} SESSIONS</Title>
            <RightHeaderContainer>
              {(selectedTags.length || (startDate && endDate)) && (
                <ClearFiltersButton onClick={handleClearFilters}>
                  Clear filters
                </ClearFiltersButton>
              )}
              <TagFilter
                tags={tags}
                selectedItem={selectedTags}
                setSelectedItem={setSelectedTags}
              />
              <DatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </RightHeaderContainer>
          </TopTableHeader>

          <TableHeaderContainer>
            <TableHeader>Date</TableHeader>
            <TableHeader>Start Time</TableHeader>
            <TableHeader>End Time</TableHeader>
            <TableHeader>Tags</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableHeaderContainer>
        </FullTableHeader>

        <TableContainer headerHeight={headerHeight}>
          <>
            <Table>
              <tbody>
                {sessions &&
                  sessions
                    .sort((a, b) => {
                      if (!a.completed_at && b.completed_at) return -1;
                      if (a.completed_at && !b.completed_at) return 1;
                      if (a.completed_at && b.completed_at) {
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                      return 0;
                    })
                    .filter((session) => {
                      const sessionTags =
                        sessionSelectedTags[session.id]?.map(
                          (tag) => tag.label
                        ) || [];
                      const sessionDate = moment(session.created_at).subtract(
                        5,
                        "hours"
                      );

                      // Define matchesTags variable
                      const matchesTags =
                        selectedTags.some((tag) => sessionTags.includes(tag)) ||
                        !selectedTags.length;

                      const isWithinDateRange =
                        (!startDate ||
                          sessionDate.isSameOrAfter(startDate, "day")) &&
                        (!endDate ||
                          sessionDate.isSameOrBefore(endDate, "day"));

                      return matchesTags && isWithinDateRange;
                    })
                    .map((row) => {
                      return (
                        <TableRow key={row.hangerId}>
                          <TableData>
                            {moment(row.created_at)
                              .subtract(5, "hours")
                              .format("MM/DD/YYYY")}
                          </TableData>
                          <TableData>
                            {moment(row.created_at)
                              .subtract(5, "hours")
                              .format("h:mm A")}
                          </TableData>
                          <TableData>
                            {row.completed_at
                              ? moment(row.completed_at)
                                  .subtract(5, "hours")
                                  .format("h:mm A")
                              : "-"}
                          </TableData>
                          <TableData style={{ padding: "0px 10px" }}>
                            <TagData
                              sessionId={row.id}
                              selectedItem={sessionSelectedTags}
                              setSelectedItem={setSessionSelectedTags}
                              tags={tags}
                              setTags={setTags}
                              setShowAlert={setShowAlert}
                              setAlertMessage={setAlertMessage}
                              setAlertType={setAlertType}
                            />
                          </TableData>
                          <TableData>
                            {row.completed_at ? (
                              <ActionButtonContainer
                                onClick={() =>
                                  handleViewInsights(row.id, row.name)
                                }
                              >
                                <ActionButton>View insights</ActionButton>
                                <AiOutlineRight style={{ color: "#a1a1a1" }} />
                              </ActionButtonContainer>
                            ) : (
                              <ActionButtonContainer
                                onClick={() => handleJoinSession(row.id)}
                              >
                                <ActionButton join>Join session</ActionButton>
                                <AiOutlineRight style={{ color: "#a1a1a1" }} />
                              </ActionButtonContainer>
                            )}
                          </TableData>
                        </TableRow>
                      );
                    })}
                {/* Repeat rows as needed */}
                <tr>
                  <td
                    colSpan={5}
                    style={{ height: "200px", background: "transparent" }}
                  />
                </tr>
              </tbody>
            </Table>
            {!sessions?.length && (
              <NoDataTableContainer>
                <GrCoatCheck />
                No Sessions
              </NoDataTableContainer>
            )}
          </>
        </TableContainer>
      </SessionTableContainer>
    </PageContainer>
  );
};

export default Sessions;
