import React from "react";
import styled from "styled-components";
import { AiOutlineInfoCircle } from "react-icons/ai";

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  margin: 0px auto 15px;
  max-width: calc(60vw - 32px);
`;

const IconContainer = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  color: #0d6efd;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const BannerTopText = styled.span`
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const BannerBottomText = styled.span`
  color: #6c757d;
  font-size: 14px;
`;

const BannerButton = styled.button`
  padding: 8px 16px;
  background-color: #f8f9fa;
  color: #515151;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #f0f1f2;
  }
`;

const BaseBanner = ({ topText, bottomText, buttonText = "Dismiss" }) => {
  const [isVisible, setIsVisible] = React.useState(true);

  if (!isVisible) return null;

  return (
    <BannerContainer>
      <IconContainer>
        <AiOutlineInfoCircle size={20} />
      </IconContainer>
      <TextContainer>
        <BannerTopText>{topText}</BannerTopText>
        <BannerBottomText>{bottomText}</BannerBottomText>
      </TextContainer>
      <BannerButton onClick={() => setIsVisible(false)}>
        {buttonText}
      </BannerButton>
    </BannerContainer>
  );
};

export default BaseBanner;
