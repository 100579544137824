import React, { useState, useEffect } from "react";
import {
  PageContainer,
  Header,
  BackButton,
  Divider,
  Spinner,
} from "./common/common-components";
import {
  processCoatData,
  calculateAverageDuration,
  formatDateTime,
} from "./common/Helper";
import styled from "styled-components";
import { AiOutlineLeft, AiOutlineLineChart } from "react-icons/ai";
import { RiDoorClosedLine } from "react-icons/ri";
import { MdOutlineAccessTime } from "react-icons/md";
import CheckItTable from "./common/CheckItTable";
import InfoCard from "./common/InfoCard";
import DoubleBarChart from "./common/DoubleBarChart";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { getCoatsBySessionId } from "../utils/api/api-consumer";
import BaseBanner from "./common/BaseBanner";
import { coatData } from "../utils/demoData";

const SessionDate = styled.div`
  color: #656565;
  margin-right: auto;
  margin-left: 20px;
  //TODO: Fix outline for the font
`;

const GraphContainer = styled.div`
  height: 350px;
  width: 100%;
  margin: 0px auto 15px;
  border: 1px solid #d1d1d1;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60vw;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 60vw;
  margin: 40px auto;
`;

const LoadingContainer = styled.div`
  position: fixed; // Make the container fixed to the viewport
  top: 0; // Align to the top
  left: 0; // Align to the left
  height: 100vh; // Fill the entire viewport height
  width: calc(100vw); // Fill the entire viewport width minus the sidebar width
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; // Ensure it's above other elements
  background-color: #f9f9f9;
`;

// {
//   name: "John Smith",
//   phone: "555-0123",
//   arrivalTime: "18:30",
//   departureTime: "22:45",
// },

const SessionInfo = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const [sessionData, setSessionData] = useState([]);
  const { sessionName } = useLocation().state || {};
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSessionInfo = async () => {
      if (sessionId === "fa1412d4-ff13-4a74-b389-98bf3eb74f84") {
        setSessionData(coatData);
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        const data = await getCoatsBySessionId(sessionId);
        setSessionData(data);
      } catch (error) {
        console.error("Error fetching session data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSessionInfo();
  }, [sessionId]);

  // Define column renderers
  const columnRenderers = {
    checked_at: (time) => formatDateTime(time),
    request_fulfilled_at: (time) => formatDateTime(time),
    phone_number: (phone) =>
      phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  // Add check for empty sessionData
  if (!sessionData || sessionData.length === 0) {
    return (
      <PageContainer>
        <Header width={60}>
          <BackButton
            variant="back-arrow"
            onClick={() => navigate("/sessions")}
          >
            <AiOutlineLeft style={{ color: "#515151" }} />
          </BackButton>
          <SessionDate>No data available</SessionDate>
        </Header>
        <Divider width={60} />
        <BaseBanner
          topText="No coat check data"
          bottomText="There is no coat check data available for this session."
          buttonText="Dismiss"
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header width={60}>
        <BackButton variant="back-arrow" onClick={() => navigate("/sessions")}>
          <AiOutlineLeft style={{ color: "#515151" }} />
        </BackButton>
        <SessionDate>{sessionName}</SessionDate>
      </Header>
      <Divider width={60} />
      {sessionData.some((coat) => !coat.request_fulfilled_at) && (
        <BaseBanner
          topText={`${
            sessionData.filter((coat) => !coat.request_fulfilled_at).length
          } coats not retrieved`}
          bottomText="Looks like some visitors forgot to pick up their coats. Find their contact information in the table below."
          buttonText="Dismiss"
        />
      )}
      <GraphContainer>
        <DoubleBarChart
          data={processCoatData(sessionData)}
          firstBarKey="checkIns"
          secondBarKey="checkOuts"
          xAxisKey="hour"
          firstBarName="Coats Checked"
          secondBarName="Coats Retrieved"
        />
      </GraphContainer>
      <MetricsContainer>
        <InfoCard
          icon={AiOutlineLineChart}
          cardTitle="Arrival Time"
          mainText={formatDateTime(sessionData[0].checked_at)}
        />
        <InfoCard
          icon={RiDoorClosedLine}
          cardTitle="Departure Time"
          mainText={formatDateTime(
            sessionData[sessionData.length - 1].request_fulfilled_at
          )}
        />
        <InfoCard
          icon={MdOutlineAccessTime}
          cardTitle="Stay Length"
          mainText={calculateAverageDuration(sessionData)}
        />
      </MetricsContainer>
      <CheckItTable
        tableInfo={sessionData}
        columnNames={{
          hanger_id: "Hanger #",
          phone_number: "Phone #",
          checked_at: "Arrival Time",
          request_fulfilled_at: "Departure Time",
        }}
        columnRenderers={columnRenderers}
        title="Coats Checked"
        rows={5}
        tags={false}
        date={false}
        actions={false}
      />
    </PageContainer>
  );
};

export default SessionInfo;
