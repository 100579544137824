import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Jost;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 10px;
  width: 65%;
  max-width: 400px;
  padding: 20px;
`;

const ModalHeader = styled.h2`
  text-align: center;
  color: #184730;
`;

const ModalList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
`;

const ModalListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  background: #184730;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const Button = styled.button`
  background: #184730;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 7px 20px;
  cursor: pointer;
  font-family: Jost;
`;

const ModalTextFlex = styled.div`
  display: flex;
  align-items: center;
`;

const InstructionsModal = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>Getting Started</ModalHeader>
        <ModalList>
          <ModalListItem key={1}>
            <Circle>{1}</Circle>
            <div>Enter your phone number</div>
          </ModalListItem>
          <ModalListItem key={2}>
            <Circle>{2}</Circle>
            <ModalTextFlex>Click Submit Button</ModalTextFlex>
          </ModalListItem>
          <ModalListItem key={3}>
            <Circle>{3}</Circle>
            <ModalTextFlex>Click Next Button</ModalTextFlex>
          </ModalListItem>
          <ModalListItem key={4}>
            <Circle>{4}</Circle>
            <div>Verify receipt of SMS</div>
          </ModalListItem>
        </ModalList>
        <Button onClick={onClose}>Let's Go!</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default InstructionsModal;
