import styled from "styled-components";

export const FeaturesSection = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 50px;
  background: #fff;
  height: 90vh;

  @media screen and (max-width: 843px) {
    flex-direction: column;
    height: auto;
  }
`;

export const FeaturesImageDesktop = styled.img`
  max-width: 30%;

  @media screen and (max-width: 843px) {
    display: none;
  }
`;

export const FeaturesImageMobile = styled.img`
  display: none;

  @media screen and (max-width: 843px) {
    margin: 50px 0px;
    display: block;
    max-width: 70%;
  }
`;

export const FeaturesText = styled.div`
  max-width: 50%; /* Adjust as needed */

  @media screen and (max-width: 843px) {
    max-width: 100%; /* Adjust as needed */
    padding: 0px 20px;
  }
`;

export const FeaturesHeading = styled.h2`
  font-size: 60px;
  font-family: Jost;
  color: #184730;
  margin-bottom: 10px;

  @media screen and (max-width: 975px) {
    font-size: 48px;
  }

  @media screen and (max-width: 843px) {
    font-size: 34px;
    margin-bottom: 30px;
  }
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  @media screen and (max-width: 843px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

export const FeatureIcon = styled.img`
  margin-right: 10px;
`;

export const FeatureText = styled.p`
  font-size: 15px;
  max-width: 450px;
  margin-left: 20px;
  text-align: left;
  font-family: Jost;
  color: #184730;
  font-weight: 500;

  @media screen and (max-width: 843px) {
    margin: 30px 0px 10px 15px;
    font-size: 19px;
  }
`;
