const policy = {
  title: `Privacy Policy`,
  subtitle: `This Privacy Policy describes how CheckIt collects, uses, and protects the personal information you provide through our website, application, or service (collectively referred to as the "Service"). Please read this Privacy Policy carefully. By using our Service, you consent to the practices described in this policy.`,
  subHeadings: [
    {
      heading: `1. Information We Collect`,
      text: `We may collect the following types of information:
            Personal Information: Information that identifies you, such as your name, and phone number.
            Non-Personal Information: Information that does not personally identify you, such as device information, IP addresses, and usage data.
            `,
    },
    {
      heading: `2. How We Use Your Information`,
      text: `We may use the information we collect for the following purposes:
      To provide and improve our Service
      To communicate with you
      To personalize your experience
      To analyze usage and improve our Service
      To comply with legal obligations`,
    },
    {
      heading: `3. Information Sharing`,
      text: `We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share information with trusted third parties who assist us in operating our Service, conducting our business, or servicing you.`,
    },
    {
      heading: `4. Cookies and Similar Technologies`,
      text: `We may use cookies and similar technologies to enhance your experience. You can choose to disable cookies in your browser settings, but this may affect your ability to use our Service.`,
    },
    {
      heading: `5. Security`,
      text: `We implement a variety of security measures to protect the confidentiality and integrity of your personal information. However, no method of transmission over the internet or electronic storage is completely secure`,
    },
    {
      heading: `6. Your Choices`,
      text: `You have the right to review, update, or delete the personal information we hold about you. To exercise these rights or for any privacy-related inquiries, please contact us at info@checkitcoats.com`,
    },
    {
      heading: `7. Changes to Privacy Policy`,
      text: `We reserve the right to modify this Privacy Policy at any time. Changes will be effective immediately upon posting within the application. `,
    },
    {
      heading: `8. Governing Law`,
      text: `This Privacy Policy is governed by and construed in accordance with the laws of the province of Ontario, Canada.`,
    },
  ],
  footer: `If you have any questions or concerns about this Privacy Policy, please contact us at info@checkitcoats.com.`,
};

export default policy;
