import React from "react";
import styled from "styled-components";
import terms from "../assets/terms-of-use";
import logo from "../assets/checkit-white.png";

const Container = styled.div`
  background-color: #fff:
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  font-family: Jost;
  color: #fff;
`;

const HeaderContainer = styled.div`
  text-align: left;
  color: #fff;
`;

const TextContainer = styled.div`
  text-align: left;
  color: #fff;
`;

const Logo = styled.img`
  width: 50%;
  max-width: 200px; /* Set a maximum width for the logo */
  margin-bottom: 20px;
  margin-top: 30px;
`;

const TermsOfUse = () => {
  return (
    <Container>
      <Logo src={logo} alt={"logo"} />
      <h1>{terms.title}</h1>
      <HeaderContainer>
        {terms.subtitle.map((item) => (
          <p>{item}</p>
        ))}
      </HeaderContainer>
      {terms.subheadings.map((item) => {
        return (
          <TextContainer>
            <h3>{item.heading}</h3>
            <p>{item.text}</p>
          </TextContainer>
        );
      })}
    </Container>
  );
};

export default TermsOfUse;
