import React from "react";
import styled from "styled-components";
import hanger from "../assets/hanger.svg";

export const HangerOne = styled.img`
  width: 16%;
  height: auto;
  position: absolute;
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate}deg);
  z-index: -1;
  top: 10%;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
export const HangerTwo = styled.img`
  width: 16%;
  height: auto;
  position: absolute;
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate}deg);
  z-index: -1;
  top: 51%;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const HangerThree = styled.img`
  width: 16%;
  height: auto;
  position: absolute;
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate}deg);
  z-index: -1;
  top: 87%;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const HangerFour = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate}deg);
  z-index: -1;
  top: 10%;

  @media screen and (min-width: 951px) {
    display: none;
  }
`;

export const HangerFive = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate}deg);
  z-index: -1;
  top: 35%;

  @media screen and (min-width: 951px) {
    display: none;
  }
`;

export const HangerSix = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate}deg);
  z-index: -1;
  top: 60%;

  @media screen and (min-width: 951px) {
    display: none;
  }
`;

export const HangerSeven = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  right: ${(props) => props.right};
  transform: rotate(${(props) => props.rotate}deg);
  z-index: -1;
  top: 85%;

  @media screen and (min-width: 951px) {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  // @media screen and (max-width: 950px) {
  //   display: none;
  // }
`;

const HangerBG = () => {
  return (
    <>
      <Row>
        <HangerOne src={hanger} alt={"hanger"} right={"-10%"} rotate={"15"} />
        <HangerOne src={hanger} alt={"hanger"} right={"10%"} rotate={"78"} />
        <HangerOne src={hanger} alt={"hanger"} right={"30%"} rotate={"235"} />
        <HangerOne src={hanger} alt={"hanger"} right={"50%"} rotate={"138"} />
        <HangerOne src={hanger} alt={"hanger"} right={"70%"} rotate={"63"} />
        <HangerOne src={hanger} alt={"hanger"} right={"90%"} rotate={"298"} />
      </Row>
      <Row>
        <HangerTwo src={hanger} alt={"hanger"} right={"-5%"} rotate={"138"} />
        <HangerTwo src={hanger} alt={"hanger"} right={"15%"} rotate={"298"} />
        <HangerTwo src={hanger} alt={"hanger"} right={"35%"} rotate={"78"} />
        <HangerTwo src={hanger} alt={"hanger"} right={"55%"} rotate={"15"} />
        <HangerTwo src={hanger} alt={"hanger"} right={"75%"} rotate={"235"} />
        <HangerTwo src={hanger} alt={"hanger"} right={"95%"} rotate={"63"} />
      </Row>
      <Row>
        <HangerThree src={hanger} alt={"hanger"} right={"5%"} rotate={"15"} />
        <HangerThree src={hanger} alt={"hanger"} right={"25%"} rotate={"298"} />
        <HangerThree src={hanger} alt={"hanger"} right={"45%"} rotate={"63"} />
        <HangerThree src={hanger} alt={"hanger"} right={"65%"} rotate={"78"} />
        <HangerThree src={hanger} alt={"hanger"} right={"85%"} rotate={"138"} />
      </Row>
      <Row>
        <HangerFour src={hanger} alt={"hanger"} right={"-10%"} rotate={"15"} />
        <HangerFour src={hanger} alt={"hanger"} right={"20%"} rotate={"78"} />
        <HangerFour src={hanger} alt={"hanger"} right={"60%"} rotate={"235"} />
        <HangerFour src={hanger} alt={"hanger"} right={"90%"} rotate={"63"} />
      </Row>
      <Row>
        <HangerFive src={hanger} alt={"hanger"} right={"0%"} rotate={"15"} />
        <HangerFive src={hanger} alt={"hanger"} right={"35%"} rotate={"78"} />
        <HangerFive src={hanger} alt={"hanger"} right={"80%"} rotate={"235"} />
      </Row>
      <Row>
        <HangerSix src={hanger} alt={"hanger"} right={"-20%"} rotate={"15"} />
        <HangerSix src={hanger} alt={"hanger"} right={"10%"} rotate={"78"} />
        <HangerSix src={hanger} alt={"hanger"} right={"50%"} rotate={"235"} />
        <HangerSix src={hanger} alt={"hanger"} right={"80%"} rotate={"63"} />
      </Row>
      <Row>
        <HangerSeven src={hanger} alt={"hanger"} right={"-10%"} rotate={"15"} />
        <HangerSeven src={hanger} alt={"hanger"} right={"25%"} rotate={"78"} />
        <HangerSeven src={hanger} alt={"hanger"} right={"60%"} rotate={"235"} />
        <HangerSeven src={hanger} alt={"hanger"} right={"90%"} rotate={"298"} />
      </Row>
    </>
  );
};

export default HangerBG;
