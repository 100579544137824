import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "./common/common-components";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #515151;
`;

const Title = styled.h1`
  font-size: 120px;
  margin: 0;
  color: #184730;
`;

const Subtitle = styled.h2`
  font-size: 24px;
  margin: 20px 0;
`;

const Description = styled.p`
  font-size: 16px;
  margin-bottom: 30px;
`;

const HomeButton = styled.button`
  background-color: #184730;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #143926;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <ContentContainer>
        <Title>404</Title>
        <Subtitle>Page Not Found</Subtitle>
        <Description>
          The page you're looking for doesn't exist or has been moved.
        </Description>
        <HomeButton onClick={() => navigate("/sessions")}>
          Return To Sessions
        </HomeButton>
      </ContentContainer>
    </PageContainer>
  );
};

export default NotFound;
