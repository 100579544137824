import React from "react";
import styled from "styled-components";

const Card = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px 35px;
  border: 1px solid #d1d1d1;
  height: 110px;
  width: 180px;
  color: #184730;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #184730;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
  svg {
    position: relative;
    top: 1.5px;
    width: 20px;
    height: 20px;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  margin: 0;
  font-weight: 500;
`;

const MainText = styled.div`
  color: #184730;
  font-size: 40px;
  font-weight: 600;
  margin-top: 22px;
`;

const InfoCard = ({ icon: Icon, cardTitle, mainText }) => {
  return (
    <Card>
      <TopSection>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <Title>{cardTitle}</Title>
      </TopSection>
      <MainText>{mainText}</MainText>
    </Card>
  );
};

export default InfoCard;
