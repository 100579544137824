// src/components/NewSubmit.js
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import logo from "../assets/checkit-new-small.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { formatPhoneNumber } from "../utils/helpers/helpers";
import { checkCoat } from "../utils/api/api-consumer";
import ConfirmSubmitModal from "./ConfirmSubmitModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  background-color: #fafafa;
  text-align: center;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10vh;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin: 50px 0 0px;
  color: #515151;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  width: 70%;
  border: 1px solid #ccc;
  //   border-radius: 5px;
  //   margin: 50px 0px 20px;
  box-sizing: border-box;
  font-size: 16px;
`;

const Button = styled.button`
  width: 70%;
  padding: 10px 20px;
  background-color: #184730; /* Adjust color as needed */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "none" : "#245b3a"}; /* Darker shade for hover */
    cursor: ${(props) =>
      props.disabled ? "not-allowed" : "pointer"}; /* Darker shade for hover */
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 25px; /* Adjusted to be slightly off the bottom */
  width: 100%;
  font-size: 8px;
  color: #999;
  z-index: 1000;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70%; // Adjust width as needed
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden; // Ensures the dropdown and input are contained
  margin: 50px 0px 20px;
`;

const CountrySelect = styled.select`
  padding: 10px;
  border: none; // Remove border for a seamless look
  cursor: pointer;
  color: #757575;
  font-size: 16px;
  appearance: none; // Remove default styling on mobile
  background: #fff; // Ensure background is transparent
  &:focus {
    outline: none; // Remove active border
  }
`;

const StyledInput = styled(Input)`
  border: none; // Remove border for a seamless look
  padding-left: 10px; // Add padding to align text
  flex: 1; // Allow input to take remaining space
  margin: 0px;
  &:focus {
    outline: none; // Remove active border
  }

  // Prevent yellow background on autofill
  &:-webkit-autofill {
    background-color: #fff; // Ensure background remains white when focused
    box-shadow: 0 0 0 1000px #fff inset; // Change to your desired background color
  }

  // Prevent yellow background when suggestion is clicked
  &:focus-visible {
    background-color: #fff; // Ensure background remains white when focused
  }
`;

const SelectedCountryCodeText = styled.div`
  padding: 10px;
  color: #757575; // Text color
  font-size: 16px; // Font size to match input
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`;

const countries = [
  { name: "US", code: "+1" },
  { name: "CA", code: "+1" },
  { name: "UK", code: "+44" },
  // Add more countries as needed
];

const NewSubmit = () => {
  const [countryCode, setCountryCode] = useState(countries[0]); // Added state for country code
  const [phoneNumber, setPhoneNumber] = useState(""); // {{ edit_1 }} Added state for phone number
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [didCheck, setDidCheck] = useState(null);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    const formattedNum = formatPhoneNumber(inputValue, countryCode);
    setPhoneNumber(formattedNum);
  };

  const handleCountryCodeChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.name === e.target.value
    );
    setCountryCode(selectedCountry);
    setPhoneNumber("");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formattedPhoneNumber =
      countryCode.code + phoneNumber.replace(/\D/g, "");
    const id = query.get("id");
    try {
      await checkCoat(id, formattedPhoneNumber);
      setDidCheck(true);
    } catch (err) {
      setDidCheck(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = "hidden";

    // Cleanup function to re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <Container>
      {isModalOpen && (
        <ConfirmSubmitModal
          onClose={() => setIsModalOpen(false)}
          phoneNumber={countryCode.code + " " + phoneNumber}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          didCheck={didCheck}
        />
      )}
      <Logo src={logo} alt="logo" />
      <Title>Welcome</Title>
      <Subtitle>
        Submit your phone number <br />
        to complete your coat check.
      </Subtitle>
      <InputContainer>
        <CountrySelect
          value={countryCode.name}
          onChange={handleCountryCodeChange} // Update country code on change
        >
          {countries.map((country) => (
            <option key={country.name} value={country.name}>
              {country.name}
            </option>
          ))}
        </CountrySelect>
        <SelectedCountryCodeText>{countryCode.code}</SelectedCountryCodeText>
        <StyledInput
          type="tel"
          inputMode="numeric"
          pattern="[0-9]*"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          autoComplete="tel"
          disabled={!query.get("id")}
        />
      </InputContainer>
      <Button onClick={() => setIsModalOpen(true)} disabled={!query.get("id")}>
        <ButtonContent>
          Submit
          <AiOutlineArrowRight />
        </ButtonContent>
      </Button>
      {!query.get("id") && (
        <div
          style={{
            color: "#515151",
            marginTop: "30px",
            fontSize: "12px",
            width: "70vw",
          }}
        >
          Please try navigating from the QR code again.
        </div>
      )}
      <Footer>
        By submitting, I agree to checkit's Terms of Use and Privacy Policy
      </Footer>
    </Container>
  );
};

export default NewSubmit;
