import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiFillCloseCircle,
} from "react-icons/ai";

// Styled Components
const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.div`
  padding: 2px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 8px;
  color: #787878;
  display: flex;
  justify-content: space-between; // Change to space-between for fixed positioning
  align-items: center;
  min-width: 65px;
  min-height: 20px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border-color: #0077ff;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 115%;
  max-width: 81px;
  min-width: 81px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style: none;
  padding: 0px 0;
  margin: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const DropdownItem = styled.li`
  padding: 10px 10px;
  cursor: pointer;
  font-size: 10px;
  color: #333;
  text-align: left;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownIcon = styled.span`
  margin-left: 10px;
  font-size: 12px;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
`;

const CoatStatusDropdown = ({
  isOpen,
  setIsOpen,
  selectedItem,
  setSelectedItem,
}) => {
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const items = ["Checked", "Requested", "Retrieved", "Fulfilled"];

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        {selectedItem}
        <DropdownIcon>
          {selectedItem === "Status" ? (
            isOpen ? (
              <AiOutlineCaretUp />
            ) : (
              <AiOutlineCaretDown style={{ marginTop: "1px" }} />
            )
          ) : (
            <AiFillCloseCircle
              size={8}
              style={{ margin: "0px" }}
              onClick={() => setSelectedItem("Status")}
            />
          )}
          {}
        </DropdownIcon>
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {items.map((item, index) => (
            <DropdownItem key={index} onClick={() => handleItemClick(item)}>
              {item}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default CoatStatusDropdown;
