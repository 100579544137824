import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import PropTypes from "prop-types";

const RevenueLineChart = ({
  data = [],
  dateKey = "date",
  revenueKey = "totalRevenue",
  isCumulative = false,
  showToggle = true,

  // Sizing props
  width = "90%",
  height = 300,
  margin = { top: 20, right: 30, left: 50, bottom: 20 },
  top = 50,
  position = "relative",

  // Line styling
  lineColor = "#184730",
  lineWidth = 2,

  // Grid styling
  showGrid = true,
  gridColor = "#e0e0e0",

  // Axis styling
  xAxisAngle = -45,
  xAxisHeight = 60,
  xAxisFontSize = 12,
  xAxisColor = "#666666",
  yAxisFontSize = 12,
  yAxisColor = "#666666",

  // Tooltip styling
  tooltipBackground = "white",
  tooltipBorder = "1px solid #d1d1d1",
  tooltipBorderRadius = "4px",

  // Currency formatting
  currencyCode = "USD",
  locale = "en-US",
}) => {
  const [showCumulative, setShowCumulative] = React.useState(isCumulative);

  // Format and calculate the data
  const formattedData = React.useMemo(() => {
    const validSessions = (data || [])
      .filter((session) => session && session[dateKey] && session[revenueKey])
      .map((session) => ({
        date: new Date(session[dateKey]),
        revenue: session[revenueKey],
      }))
      .sort((a, b) => a.date - b.date);

    if (showCumulative) {
      let runningTotal = 0;
      return validSessions.map((item) => ({
        ...item,
        revenue: (runningTotal += item.revenue),
      }));
    }

    return validSessions;
  }, [data, dateKey, revenueKey, showCumulative]);

  // Format currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  // Format date
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(locale, {
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div
      style={{ width: "100%", height: "100%", position: "relative", top: top }}
    >
      {showToggle && (
        <div
          style={{
            position: "absolute",
            top: "-30px",
            right: "35px",
          }}
        >
          <label style={{ fontSize: "14px", color: "#666" }}>
            <input
              type="checkbox"
              checked={showCumulative}
              onChange={(e) => setShowCumulative(e.target.checked)}
              style={{ marginRight: "6px" }}
            />
            Show Cumulative Revenue
          </label>
        </div>
      )}

      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={formattedData}
          margin={margin}
          position={position}
          top={top}
        >
          {showGrid && (
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={gridColor}
              vertical={false}
            />
          )}

          <XAxis
            dataKey="date"
            height={xAxisHeight}
            angle={xAxisAngle}
            textAnchor="end"
            tick={{
              fontSize: xAxisFontSize,
              fill: xAxisColor,
            }}
            tickFormatter={formatDate}
          />

          <YAxis
            tick={{
              fontSize: yAxisFontSize,
              fill: yAxisColor,
            }}
            tickFormatter={formatCurrency}
          />

          <Tooltip
            contentStyle={{
              backgroundColor: tooltipBackground,
              border: tooltipBorder,
              borderRadius: tooltipBorderRadius,
            }}
            formatter={(value) => [formatCurrency(value), "Revenue"]}
            labelFormatter={(date) => formatDate(date)}
          />

          <Line
            type="monotone"
            dataKey="revenue"
            stroke={lineColor}
            strokeWidth={lineWidth}
            dot={{ r: 4 }}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

RevenueLineChart.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      totalRevenue: PropTypes.number,
    })
  ),
  dateKey: PropTypes.string,
  revenueKey: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  margin: PropTypes.object,
  lineColor: PropTypes.string,
  lineWidth: PropTypes.number,
  showGrid: PropTypes.bool,
  gridColor: PropTypes.string,
  xAxisAngle: PropTypes.number,
  xAxisHeight: PropTypes.number,
  xAxisFontSize: PropTypes.number,
  xAxisColor: PropTypes.string,
  yAxisFontSize: PropTypes.number,
  yAxisColor: PropTypes.string,
  tooltipBackground: PropTypes.string,
  tooltipBorder: PropTypes.string,
  tooltipBorderRadius: PropTypes.string,
  currencyCode: PropTypes.string,
  locale: PropTypes.string,
  isCumulative: PropTypes.bool,
  showToggle: PropTypes.bool,
};

export default RevenueLineChart;
