import React, { useEffect, useState } from "react";
import {
  PageContainer,
  Header,
  Divider,
  Spinner,
} from "./common/common-components";
import styled from "styled-components";
import {
  AiOutlineLineChart,
  AiOutlineUser,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { TbDoorEnter, TbDoorExit } from "react-icons/tb";
import { MdOutlineAccessTime } from "react-icons/md";
import InfoCard from "./common/InfoCard";
import DoubleBarChart from "./common/DoubleBarChart";
import LineChart from "./common/LineChart";
import DatePicker from "./DatePicker";
import { getBarDashboardData } from "../utils/api/api-consumer";
import { useUser } from "../context/UserContext";
import {
  calculateAverageTimes,
  getDistinctVisitors,
  getTotalVisitors,
  processRevenue,
  processCoatData,
} from "../utils/helpers/helpers";

const SessionDate = styled.div`
  color: #656565;
  margin-right: auto;
  //TODO: Fix outline for the font
`;

const GraphContainerHeader = styled.div`
  width: 100%;
  max-width: 60vw;
  margin: auto;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d1d1;
  background-color: white;
  border-radius: 10px;
`;

const ToggleContainer = styled.div`
  display: flex;
  margin-left: 15px;
  background-color: #efefef;
  padding: 2px 2px;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
  position: relative;
`;

const ToggleElementsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  color: ${(props) => (props.active ? "#656565" : "#a1a1a1")};
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => !props.active && "#666666"};
  }
`;

const SlideBackground = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  height: calc(100% - 4px);
  width: ${(props) => (props.active === "insights" ? "94px" : "72px")};
  background-color: white;
  border-radius: 4px;
  transition: transform 0.3s ease;
  transform: translateX(
    ${(props) => (props.active === "insights" ? "72px" : "0")}
  );
`;

const GraphContainer = styled.div`
  height: 450px;
  width: 100%;
  margin: 15px auto;
  padding-top: 20px;
  border: 1px solid #d1d1d1;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60vw;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 60vw;
  margin: 40px auto;
`;

const LoadingContainer = styled.div`
  position: fixed; // Make the container fixed to the viewport
  top: 0; // Align to the top
  left: 0; // Align to the left
  height: 100vh; // Fill the entire viewport height
  width: calc(100vw); // Fill the entire viewport width minus the sidebar width
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; // Ensure it's above other elements
  background-color: #f9f9f9;
`;

const Dashboard = () => {
  const [activeView, setActiveView] = useState("revenue");
  const [sessionData, setSessionData] = useState({});
  const [timeData, setTimeData] = useState({});
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add this state

  const { barId } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      if (!(startDate && endDate)) {
        return;
      }
      setIsLoading(true);

      const data = await getBarDashboardData(
        barId,
        startDate.toISOString(),
        endDate.toISOString()
      );
      // This should look like sessionID: [coat data...]
      setTimeData(calculateAverageTimes(data));
      setSessionData(data);
      setIsLoading(false);
    };
    fetchData();
  }, [barId, startDate, endDate]);

  useEffect(() => {
    setIsEmptyData(
      !sessionData ||
        Object.keys(sessionData).length === 0 ||
        sessionData[Object.keys(sessionData)[0]].length === 0
    );
  }, [sessionData]);

  return (
    <>
      {isLoading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : null}
      <PageContainer style={{ paddingBottom: "60px" }}>
        <Header width={60}>
          <SessionDate>Dashboard</SessionDate>
        </Header>

        <Divider width={60} />

        <GraphContainerHeader>
          <ToggleElementsContainer>
            <ToggleContainer>
              <SlideBackground active={activeView} />
              <ToggleButton
                active={activeView === "revenue"}
                onClick={() => setActiveView("revenue")}
              >
                Revenue
              </ToggleButton>
              <ToggleButton
                active={activeView === "insights"}
                onClick={() => setActiveView("insights")}
              >
                User Insights
              </ToggleButton>
            </ToggleContainer>
            <div style={{ marginRight: "15px" }}>
              <DatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </div>
          </ToggleElementsContainer>
        </GraphContainerHeader>

        <MetricsContainer>
          <InfoCard
            icon={activeView === "revenue" ? AiOutlineLineChart : TbDoorEnter}
            cardTitle={activeView === "revenue" ? "Revenue" : "Arrival Time"}
            mainText={
              activeView === "revenue"
                ? getTotalVisitors(sessionData) * 5
                : isEmptyData
                ? "N/A"
                : timeData.averageArrivalTime
            }
          />
          <InfoCard
            icon={activeView === "revenue" ? AiOutlineUser : TbDoorExit}
            cardTitle={activeView === "revenue" ? "Visitors" : "Departure Time"}
            mainText={
              activeView === "revenue"
                ? getTotalVisitors(sessionData)
                : isEmptyData
                ? "N/A"
                : timeData.averageDepartureTime
            }
          />
          <InfoCard
            icon={
              activeView === "revenue"
                ? AiOutlineUsergroupAdd
                : MdOutlineAccessTime
            }
            cardTitle={
              activeView === "revenue" ? "New Visitors" : "Stay Length"
            }
            mainText={
              activeView === "revenue"
                ? getDistinctVisitors(sessionData)
                : isEmptyData
                ? "N/A"
                : timeData.delta
            }
          />
        </MetricsContainer>
        <GraphContainer>
          {!isEmptyData ? (
            activeView === "insights" ? (
              <DoubleBarChart
                data={processCoatData(sessionData)}
                firstBarKey="checkIns"
                secondBarKey="checkOuts"
                xAxisKey="hour"
                firstBarName="Coats Checked"
                secondBarName="Coats Retrieved"
                height={400}
              />
            ) : (
              <LineChart
                data={processRevenue(sessionData)}
                xAxisKey="date"
                lineKey="totalRevenue"
                isCumulative={true}
                height={400}
              />
            )
          ) : (
            <div style={{ textAlign: "center", padding: "2rem" }}>
              No data available for the selected date range. Try adjusting your
              filters.
            </div>
          )}
        </GraphContainer>
      </PageContainer>
    </>
  );
};

export default Dashboard;
