// src/components/DatePicker.js
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineArrowRight,
} from "react-icons/ai";

const DatePickerContainer = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  font-size: 13px;
  color: #a1a1a1;
  padding: 5px 8px;
  margin-left: 10px;
  min-width: 175px;
  cursor: pointer;
`;

const DatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CalendarContainer = styled.div`
  position: absolute;
  top: 120%;
  left: 0%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 10000;
`;

const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
`;

const Day = styled.div`
  padding: 10px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  transition: none;

  color: ${(props) => (props.selected ? "#fff" : "#515151")};
  background-color: ${(props) => (props.selected ? "#184730" : "none")};

  &:hover:not([disabled]) {
    background-color: ${(props) => (props.selected ? "none" : "#f0f0f0")};
    transition: ease-in 0.1s;
  }

  &.selected {
    background-color: ;
    color: white;
  }
`;

const MonthContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const SmallBox = styled.div`
  border: 1px solid #ccc;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f0f0f0;
    transition: ease-in-out 0.2s;
    cursor: pointer;
  }
`;

const MonthText = styled.div`
  width: 120px; // Set a fixed width for the month text
  text-align: center; // Center the text
  font-weight: 500;
  margin: 15px 0px;
  font-size: 15px;
`;

const FromToContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 50%;
  align-items: center;
`;

const ToTextIconContainer = styled.div`
  display: flex;
  align-items: centerl;
`;

const DatePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else {
      if (date < startDate) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }

      setIsOpen(false);
    }
  };

  const renderCalendar = () => {
    const daysInMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    const firstDayOfWeek = firstDayOfMonth.getDay() || 7; // Sunday - 0, Monday - 1, ..., Saturday - 6
    const daysBefore = firstDayOfWeek === 7 ? 0 : firstDayOfWeek;
    const daysAfter =
      daysBefore === 6 || (daysInMonth === 31 && daysBefore === 5)
        ? 42 - daysInMonth - daysBefore
        : 35 - daysInMonth - daysBefore;

    return (
      <CalendarContainer>
        <MonthContainer>
          <SmallBox
            onClick={() =>
              setCurrentMonth(
                new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() - 1
                )
              )
            }
          >
            <AiOutlineLeft style={{ color: "#D1D1D1" }} />
          </SmallBox>
          <MonthText>
            {currentMonth.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </MonthText>
          <SmallBox
            onClick={() =>
              setCurrentMonth(
                new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() + 1
                )
              )
            }
          >
            <AiOutlineRight style={{ color: "#D1D1D1" }} />
          </SmallBox>
        </MonthContainer>
        <Calendar>
          {[...Array(daysBefore)].map((_, index) => (
            <Day key={index} disabled style={{ color: "#D1D1D1" }}>
              {new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth(),
                firstDayOfMonth.getDate() - daysBefore
              ).getDate() + index}
            </Day>
          ))}
          {[...Array(daysInMonth)].map((_, index) => {
            const isSelectedStartDate =
              startDate &&
              startDate.getDate() === index + 1 &&
              currentMonth.getMonth() === startDate.getMonth() &&
              currentMonth.getFullYear() === startDate.getFullYear();

            const isSelectedEndDate =
              endDate &&
              endDate.getDate() === index + 1 &&
              currentMonth.getMonth() === endDate.getMonth() &&
              currentMonth.getFullYear() === endDate.getFullYear();

            return (
              <Day
                key={index}
                selected={isSelectedStartDate || isSelectedEndDate}
                onClick={() =>
                  handleDateClick(
                    new Date(
                      currentMonth.getFullYear(),
                      currentMonth.getMonth(),
                      index + 1
                    )
                  )
                }
              >
                {index + 1}
              </Day>
            );
          })}
          {[...Array(daysAfter)].map((_, index) => (
            <Day
              key={index + daysInMonth}
              disabled
              style={{ color: "#D1D1D1" }}
            >
              {new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth() + 1,
                index + 1
              ).getDate()}
            </Day>
          ))}
        </Calendar>
      </CalendarContainer>
    );
  };

  return (
    <DatePickerContainer ref={dropdownRef}>
      <DatePickerHeader onClick={() => setIsOpen(!isOpen)}>
        <FromToContainer>
          {!startDate ? (
            <span>From</span>
          ) : (
            <span>{startDate.toLocaleDateString()}</span>
          )}
        </FromToContainer>
        <FromToContainer>
          <ToTextIconContainer>
            <AiOutlineArrowRight style={{ margin: "1px 6px 0px -7px" }} />
            {!endDate ? (
              <span>To</span>
            ) : (
              <span>{endDate.toLocaleDateString()}</span>
            )}
          </ToTextIconContainer>
        </FromToContainer>
      </DatePickerHeader>
      {isOpen && <div className="calendar-container">{renderCalendar()}</div>}
    </DatePickerContainer>
  );
};

export default DatePicker;
