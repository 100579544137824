import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlineLeft } from "react-icons/ai";
import { Divider } from "./common/common-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10100;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 65%;
  max-width: 400px;
`;

const Header = styled.div`
  font-size: 20px;
  color: #656565;
  width: 100%;
  text-align: left;
  font-weight: 600;
`;

const FormSection = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Label = styled.div`
  font-size: 16px;
  color: #515151;
  margin: auto;
  width: 95%;
  text-align: left;
`;

const Input = styled.input`
  width: 91%;
  padding: 8px;
  margin: 8px auto auto auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #184730;
  }
`;

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px 10px;
  margin: 20px 0;
  justify-items: center;

  /* Style for the second row (items 6-9) */
  & > div:nth-child(n + 6):nth-child(-n + 9) {
    position: relative;
    left: calc(50% + 5px);
  }
`;

const ColorDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border: 2px solid ${(props) => (props.selected ? "#656565" : "transparent")};
  transition: all 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 25px;
`;

const Button = styled.button`
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  background-color: ${(props) => (props.primary ? "#184730" : "transparent")};
  color: ${(props) => (props.primary ? "white" : "#656565")};
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: -20px;
`;

const NewTagModal = ({ isOpen, onClose, onCreateTag }) => {
  const [label, setLabel] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const colors = [
    "#BEC2C8", // Grey
    "#96A2B3", // Purple
    "#5F6DCF", // Blue
    "#32B5CD", // Cyan
    "#51B684", // Green
    "#F1C857", // Gold
    "#F09952", // Orange
    "#F6C8C2", // Pink
    "#E9595B", // Red
  ];

  if (!isOpen) return null;

  const handleCreate = () => {
    let hasError = false;

    if (!label || !selectedColor) {
      setErrorMsg("Please enter a label and select a color");
      hasError = true;
    }

    if (!hasError) {
      onCreateTag({ label, color: selectedColor });
      setLabel("");
      setSelectedColor(null);
      setErrorMsg("");
      onClose();
    }
  };

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
    if (errorMsg) setErrorMsg("");
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    if (errorMsg) setErrorMsg("");
  };

  return (
    <ModalOverlay onClick={(e) => e.target === e.currentTarget && onClose()}>
      <ModalContent>
        <Header>Create Tag</Header>
        <Divider />
        <FormSection>
          <Label>Label </Label>
          <Input type="text" value={label} onChange={handleLabelChange} />
        </FormSection>

        <FormSection>
          <Label>Color</Label>
          <ColorGrid>
            {colors.map((color) => (
              <ColorDot
                key={color}
                color={color}
                selected={selectedColor === color}
                onClick={() => handleColorSelect(color)}
              />
            ))}
          </ColorGrid>
          {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        </FormSection>

        <ButtonContainer>
          <Button onClick={onClose}>
            <AiOutlineLeft />
            Back
          </Button>
          <Button primary onClick={handleCreate}>
            Create
          </Button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default NewTagModal;
