const terms = {
  title: `Terms of Use`,
  subtitle: [
    `The Terms of Use (or "Terms") govern your use of CheckIt, except where we expressly state that separate terms (and not these) apply, and provide information about the CheckIt Service (the "Service"), outlined below. When you check a coat using Checkit, you agree to these terms.`,
    `ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN SOME EXCEPTIONS AND HOW YOU CAN OPT OUT OF ARBITRATION BELOW.`,
  ],
  subheadings: [
    {
      heading: `The CheckIt Service`,
      text: `We agree to provide you with the CheckIt Service. The Service includes all of the CheckIt features which allows you to check your coat with ease. The Service is made up of the following aspects:`,
    },
    {
      heading: `1. Account Setup and Responsibilities`,
      text: `To access and use the CheckIt Service, you will be required to enter your phone number. You agree to provide accurate and complete information during the check in process and to update such information to keep it accurate and current. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your CheckIt session. `,
    },
    {
      heading: `2. Use of the CheckIt Service`,
      text: `You agree to use the CheckIt Service in compliance with these Terms and all applicable laws and regulations. You may only use the Service for lawful purposes and shall not engage in any prohibited activities, including but not limited to:
      Violating any provincial, or international law.
      Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity.
      `,
    },
    {
      heading: `3. Payment and Fees`,
      text: `Certain features of the CheckIt Service will require payment. You agree to pay all applicable fees and charges incurred in connection with your use of the Service. Payment will be processed through the payment method you designate.`,
    },
    {
      heading: `4. Modifications to the Service`,
      text: `We reserve the right to modify or discontinue, temporarily or permanently, the CheckIt Service (or any part thereof) with or without notice. You agree that we shall not be liable to you or any third party for any modification, suspension, or discontinuation of the Service.`,
    },
    {
      heading: `6. Termination`,
      text: `We may terminate or suspend your access to the CheckIt Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.`,
    },
    {
      heading: `7. Disclaimer of Warranties`,
      text: `The CheckIt Service is provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted or error-free, nor do we make any warranty as to the results that may be obtained from the use of the Service.`,
    },
    {
      heading: `8. Limitation of Liability`,
      text: `In no event shall CheckIt or its partners, or employees be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (i) your access to or use of or inability to access or use the CheckIt Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.`,
    },
  ],
};

export default terms;
