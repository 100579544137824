// src/context/UserContext.js
import React, { createContext, useContext, useState } from "react";

// Create a Context
const UserContext = createContext();

// Create a Provider Component
export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [barId, setBarId] = useState(null);

  return (
    <UserContext.Provider value={{ userId, setUserId, barId, setBarId }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => {
  return useContext(UserContext);
};
