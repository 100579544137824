import React, { useState, useEffect, useMemo } from "react";
import { socket } from "../utils/socket/socket";
import styled from "styled-components";
import {
  AiOutlineMore,
  AiFillDelete,
  AiOutlineRightCircle,
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineCloseSquare,
  AiOutlineCheckSquare,
} from "react-icons/ai";
import AllCoatsModal from "./AllCoatsModal";
// import { deleteSession } from "../utils/api/api-consumer";
// import { hangerIdFormatter } from "../utils/helpers/helpers";

const Container = styled.div`
  display: flex;
  background-color: #184730;
  justify-content: space-around;
  position: relative;
  height: 100vh;
  font-family: Jost;
`;

const VerticalLine = styled.div`
  position: absolute;
  left: 50%;
  top: 40px; /* Adjust as needed */
  bottom: 15vh; /* Adjust as needed */
  width: 3px;
  background-color: #fff;
  transform: translateX(-50%);
`;

const Column = styled.div`
  flex: 1;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
  width: 100%;
  position: relative;
`;

const ElementContainer = styled.div`
  max-height: 75%; /* Adjust as needed */
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Element = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  width: 60%;
  color: #184730;
`;

const Checkbox = styled.input`
  margin-right: 10px;
  accent-color: #184730;
`;

const Text = styled.div`
  flex: 1;
  text-align: center;
`;

const Button = styled.div`
  color: #184730;
  padding: 3px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #e1e1e1;
    transition: ease-in-out 0.2s;
    border-radius: 10%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: #184730;
  position: relative;
`;

const IconWrapper = styled.div`
  padding: 3px 1px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #e1e1e1;
    transition: ease-in-out 0.2s;
    border-radius: 10%;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  left: 80%;
  top: 80%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const DropdownItem = styled.div`
  padding: 5px 8px;
  cursor: pointer;
  width: 70px;
  font-size: 12px;

  &:hover {
    background-color: #f9f9f9;
    transition: ease-in-out 0.2s;
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  top: 0;
  margin-top: 5px;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }
`;

const SearchBarWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  margin-top: 10px;
`;

const SearchBar = styled.input`
  padding: 2px 9px;
  width: 100px;
  font-size: 10px;
  font-family: Jost;
  transition: ease-in-out 0.2s;
  margin-right: 5px;
`;

const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BulkMoveElement = styled.div`
  position: absolute;
  bottom: 2%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  width: 40%;
  color: #184730;
`;

const StyledButton = styled.button`
  background: #fff;
  border-radius: 7px;
  border: none;
  padding: 7px 15px;
  cursor: pointer;
  position: absolute;
  bottom: 5.5%;
  left: 50%;
  margin-left: -46.695px;
  font-family: Jost;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const CoatCheckDisplay = () => {
  const [request, setRequest] = useState([]);
  const [pickup, setPickup] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState(""); // State to store the search text
  const [confirmOpen, setConfirmOpen] = useState({});
  const [selectedRequest, setSelectedRequest] = useState([]);
  const [selectedPickup, setSelectedPickup] = useState([]);
  const [notMount, setNotMount] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  useEffect(() => {
    socket.connect("/");
    let oldRequest = sessionStorage.getItem("request");
    let oldPickup = sessionStorage.getItem("pickup");

    if (oldRequest) {
      oldRequest = JSON.parse(oldRequest).request;
      setRequest([...oldRequest]);
    }

    if (oldPickup) {
      oldPickup = JSON.parse(oldPickup).pickup;
      setPickup([...oldPickup]);
    }

    socket.emit("join", { barId: query.get("id") });

    return () => {
      socket.disconnect();
    };
  }, [query]);

  useEffect(() => {
    if (request.length || notMount) {
      const stringifiedRequest = JSON.stringify({ request });
      sessionStorage.setItem("request", stringifiedRequest);
    }

    if (pickup.length || notMount) {
      const stringifiedPickup = JSON.stringify({ pickup });
      sessionStorage.setItem("pickup", stringifiedPickup);
    }
  }, [request, pickup, notMount]);

  socket.on("newRequest", (arg) => {
    if (!request.includes(arg)) {
      setRequest([...request, arg]);
      console.log(`added coat: ${arg} to the coats array`);
    }
  });

  const handleSearchIconClick = () => {
    setSearchOpen(!searchOpen); // Clear the search text
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };

  const handleButtonClick = (id) => {
    setNotMount(true);
    if (id === "bulkLeft") {
      setRequest(request.filter((item) => !selectedRequest.includes(item)));
      setPickup([...pickup, ...selectedRequest]);
      setSelectedRequest([]);
    } else {
      setRequest(request.filter((item) => item !== id));
      setPickup([...pickup, id]);
      setSelectedRequest(selectedRequest.filter((item) => item !== id));
    }
  };

  const handleMoveLeft = (id) => {
    setNotMount(true);
    setPickup(pickup.filter((item) => item !== id));
    setSelectedPickup(selectedPickup.filter((item) => item !== id));
    setRequest([...request, id]);
  };

  const handleCheckboxChange = (column, e) => {
    if (column === "right") {
      e.target.checked
        ? setSelectedPickup([...selectedPickup, e.target.value])
        : setSelectedPickup(
            selectedPickup.filter((item) => item !== e.target.value)
          );
    } else {
      e.target.checked
        ? setSelectedRequest([...selectedRequest, e.target.value])
        : setSelectedRequest(
            selectedRequest.filter((item) => item !== e.target.value)
          );
    }
  };

  const handleDeleteClick = (id) => {
    // Toggle dropdown visibility for the specific element
    setConfirmOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleConfirmDeleteClick = async (action, id) => {
    setNotMount(true);
    if (action === "delete") {
      if (id === "bulkRight") {
        // NOTE: pretty sure delete issue was coming from redefinition of id
        // for (const id of selectedPickup) {
        //   const formattedHangerId = hangerIdFormatter(id);
        //   const status = await deleteSession(formattedHangerId);
        //   if (status === "success") {
        //     setPickup(pickup.filter((item) => item !== id));
        //   }
        // }
        setPickup(pickup.filter((item) => !selectedPickup.includes(item)));
        setSelectedPickup([]);
      } else {
        // const formattedHangerId = hangerIdFormatter(id);
        // const status = await deleteSession(formattedHangerId);
        // if (status === "success") {
        //   setPickup(pickup.filter((item) => item !== id));
        // }
        setPickup(pickup.filter((item) => item !== id));
      }
    }

    setConfirmOpen((prevState) => ({
      ...prevState,
      [id]: false, // Close the dropdown for the specific element
    }));
  };

  const handleDropdownItemClick = (action, id) => {
    handleMoveLeft(id);
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: false, // Close the dropdown for the specific element
    }));
  };

  const handleIconClick = (id) => {
    // Toggle dropdown visibility for the specific element
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Container>
      <Column>
        <Heading>Requested</Heading>
        <ElementContainer>
          {request.map((item) => (
            <Element key={item}>
              <Checkbox
                type="checkbox"
                onChange={(e) => handleCheckboxChange("left", e)}
                value={item}
              />
              <Text>{item}</Text>
              <Button>
                <AiOutlineRightCircle onClick={() => handleButtonClick(item)} />
              </Button>
            </Element>
          ))}
        </ElementContainer>
        {selectedRequest.length ? (
          <BulkMoveElement>
            <Text>{selectedRequest.length} Items Selected</Text>

            <Button>
              <AiOutlineRightCircle
                onClick={() => handleButtonClick("bulkLeft")}
              />
            </Button>
          </BulkMoveElement>
        ) : null}
      </Column>

      <VerticalLine />

      <Column>
        <Heading>
          {!searchOpen ? (
            <SearchIconWrapper>
              <AiOutlineSearch onClick={handleSearchIconClick} />
            </SearchIconWrapper>
          ) : (
            <SearchBarWrapper>
              <SearchBar
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearchChange}
              />

              <AiOutlineClose
                style={{ "margin-top": "3px", cursor: "pointer" }}
                size={16}
                onClick={() => {
                  setSearchText("");
                  setSearchOpen(false);
                }}
              />
            </SearchBarWrapper>
          )}
          Waiting for Pickup
        </Heading>

        <ElementContainer>
          {pickup
            .filter((item) => item.includes(searchText))
            .map((item) => (
              <Element key={item}>
                <Checkbox
                  type="checkbox"
                  onChange={(e) => handleCheckboxChange("right", e)}
                  value={item}
                />
                <Text>{item}</Text>
                {confirmOpen[item] ? (
                  <ConfirmWrapper>
                    <IconWrapper
                      onClick={() => handleConfirmDeleteClick("cancel", item)}
                    >
                      <AiOutlineCloseSquare />
                    </IconWrapper>
                    <IconWrapper
                      onClick={() => handleConfirmDeleteClick("delete", item)}
                    >
                      <AiOutlineCheckSquare />
                    </IconWrapper>
                  </ConfirmWrapper>
                ) : (
                  <IconContainer>
                    <IconWrapper onClick={() => handleDeleteClick(item)}>
                      <AiFillDelete />
                    </IconWrapper>
                    <IconWrapper onClick={() => handleIconClick(item)}>
                      <AiOutlineMore />
                    </IconWrapper>
                    <Dropdown isOpen={dropdownOpen[item]}>
                      <DropdownItem
                        onClick={() =>
                          handleDropdownItemClick("Move Left", item)
                        }
                      >
                        Move Left
                      </DropdownItem>
                    </Dropdown>
                  </IconContainer>
                )}
              </Element>
            ))}
        </ElementContainer>
        {selectedPickup.length ? (
          <BulkMoveElement isShowing={selectedPickup.length}>
            <Text>{selectedPickup.length} Items Selected</Text>
            {confirmOpen["bulkRight"] ? (
              <ConfirmWrapper>
                <IconWrapper
                  onClick={() =>
                    handleConfirmDeleteClick("cancel", "bulkRight")
                  }
                >
                  <AiOutlineCloseSquare />
                </IconWrapper>
                <IconWrapper
                  onClick={() =>
                    handleConfirmDeleteClick("delete", "bulkRight")
                  }
                >
                  <AiOutlineCheckSquare />
                </IconWrapper>
              </ConfirmWrapper>
            ) : (
              <IconContainer>
                <IconWrapper onClick={() => handleDeleteClick("bulkRight")}>
                  <AiFillDelete />
                </IconWrapper>
              </IconContainer>
            )}
          </BulkMoveElement>
        ) : null}
      </Column>
      <StyledButton onClick={openModal}>View Coats</StyledButton>
      <AllCoatsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        barId={query.get("id")}
      />
    </Container>
  );
};

export default CoatCheckDisplay;
