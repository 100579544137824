import React, { useEffect } from "react";
import {
  PageContainer,
  Header,
  Divider,
  ButtonGroup,
  Button,
  InsideButton,
} from "./common/common-components";
import CheckItTable from "./common/CheckItTable";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useState } from "react";
import NewTagModal from "./NewTagModal";
import styled from "styled-components";
import { useUser } from "../context/UserContext";
import { createTag, getTags } from "../utils/api/api-consumer";
import AlertBanner from "./common/AlertBanner";

const ColorDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TagsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tags, setTags] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { barId } = useUser();

  useEffect(() => {
    const fetchTagsByBarId = async () => {
      const tags = await getTags(barId);
      setTags(tags);
    };

    if (barId) {
      fetchTagsByBarId();
    }
  }, [barId]);

  const columnRenderers = {
    color: (value) => (
      <CenteredContainer>
        <ColorDot color={value} />
      </CenteredContainer>
    ),
  };

  const handleAddTag = async (newTag) => {
    await createTag(barId, newTag.label, newTag.color);
    setAlertMessage(`${newTag.label} created`);
    setAlertType(`success`);
    setShowAlert(true);
    setTags([
      ...tags,
      {
        label: newTag.label,
        color: newTag.color,
        session_count: 0, // New tags start with 0 sessions
      },
    ]);
  };

  return (
    <>
      {showAlert && (
        <AlertBanner
          message={alertMessage}
          type={alertType}
          duration={2000}
          onClose={() => setShowAlert(false)}
        />
      )}
      <PageContainer>
        <Header width={60}>
          <span style={{ color: "#656565" }}>Tags</span>
          <ButtonGroup>
            <Button primary onClick={() => setIsModalOpen(true)}>
              <InsideButton>
                <AiOutlinePlusCircle style={{ marginRight: "8px" }} />
                New Tag
              </InsideButton>
            </Button>
          </ButtonGroup>
        </Header>
        <Divider width={60} />

        <CheckItTable
          tableInfo={tags}
          columnNames={{
            label: "Label",
            color: "Color",
            session_count: "Sessions",
          }}
          columnRenderers={columnRenderers}
          title="tags"
          rows={10}
          tags={false}
          date={false}
          actions={false}
        />

        <NewTagModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCreateTag={handleAddTag}
        />
      </PageContainer>
    </>
  );
};

export default TagsPage;
