// Modal.js
import React from "react";
import styled from "styled-components";
import { AiOutlineRightCircle } from "react-icons/ai";
import PulseLoader from "react-spinners/PulseLoader";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: Jost;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 75%;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmButton = styled.button`
  border-radius: 5px;
  border: none;
  background-color: #184730;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 12px 5px 15px;
  display: flex;
  font-family: Jost;
  justify-content: space-around;
  align-items: center;
  min-width: 96px;
  min-height: 35px;
`;

const ArrowContainer = styled.div`
  padding-left: 7px;
  padding-top: 4px;
`;

const Modal = ({ isOpen, onClose, children, onConfirm, isLoading }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={onClose}>
          {/* Replace the placeholder with your close button icon */}
          &times;
        </CloseButton>
        {children}
        <ButtonWrapper>
          <ConfirmButton onClick={onConfirm}>
            {isLoading ? (
              <PulseLoader color="white" size={5} />
            ) : (
              <>
                Confirm
                <ArrowContainer>
                  <AiOutlineRightCircle />
                </ArrowContainer>
              </>
            )}
          </ConfirmButton>
        </ButtonWrapper>
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
