import styled from "styled-components";

export const PageContainer = styled.div`
  //   padding: 20px;
  background-color: #f9f9f9;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px 0;
  font-size: 24px;
  font-weight: bold;
  width: ${(props) => props.width}vw;
  margin: auto;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #ccc;
  margin: 20px auto 30px;
  width: ${(props) => props.width}vw;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const Button = styled.button`
  background-color: ${(props) => (props.primary ? "#184730" : "#fff")};
  color: ${(props) => (props.primary ? "#fff" : "#656565")};
  border: ${(props) => (props.primary ? "none" : "1px solid #ccc")};
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#004d00" : "#f0f0f0")};
  }
`;

export const InsideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const BackButton = styled.div`
  height: 28px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1px;
  position: relative;
  cursor: pointer;
  &:hover {
    color: #656565;
  }
`;

export const NoDataContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  color: #a1a1a1;
  font-size: 14px;
`;

export const Spinner = styled.div`
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #184730;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
