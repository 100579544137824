import React, { useEffect, useState } from "react";
import { PageContainer, Header, Divider } from "./common/common-components";
import styled from "styled-components";
import { useUser } from "../context/UserContext";
import { getUsersByBarId } from "../utils/api/api-consumer";
import { capitalizeFirstLetter } from "../utils/helpers/helpers";
import { GrCoatCheck } from "react-icons/gr";

const RolesTableContainer = styled.div`
  background-color: #fff;
  width: 60vw;
  height: calc(
    100vh - 150px
  ); // Adjust this value based on your header/footer height
  border-radius: 10px;
  border: 1px solid #ccc;
  margin: auto;
`;

const FullTableHeader = styled.div`
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  z-index: 100;
`;

const TopTableHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  background-color: #fff;
  z-index: 1000;
  border-radius: 10px;
`;

const Title = styled.h3`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
`;

const TableContainer = styled.div`
  max-height: calc(100% - ${(props) => props.headerHeight}px);
  overflow-y: scroll;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #515151;
  max-height: 80%;
`;

const TableRow = styled.tr`
  border-top: 1px solid #eee;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 70px;
  padding-right: 60px;
`;

const TableHeaderContainer = styled.div`
  width: 100%;
  box-shadow: inset 0 1px 0 #ccc, inset 0 -1px 0 #ccc; // Create inner top and bottom borders
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  padding-right: 60px;
  padding-left: 40px;
  box-sizing: border-box;
  align-items: center;
  // position: sticky; // Make the table header sticky
  // top: ${(props) => props.headerHeight}px; // Use dynamic height
`;

const TableHeader = styled.div`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  width: 100px; // Set a fixed width for the header
`;

const TableData = styled.td`
  padding: 20px 10px;
  font-size: 14px;
  width: 100px; // Set a fixed width for the data cells
`;

const NoDataTableContainer = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  color: #a1a1a1;
  font-size: 14px;
`;

const Roles = () => {
  const { barId } = useUser(); // Access barId from UserContext

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getUsersByBarId(barId);
      setUsers(users);
    };

    fetchUsers();
  }, [barId]);

  return (
    <PageContainer>
      <Header width={60}>
        <span style={{ color: "#656565" }}>Roles</span>
      </Header>

      <Divider width={60} />

      <RolesTableContainer>
        <FullTableHeader>
          <TopTableHeader>
            <Title>100 ROLES</Title>
          </TopTableHeader>

          <TableHeaderContainer>
            <TableHeader>Email</TableHeader>
            <TableHeader>Role</TableHeader>
          </TableHeaderContainer>
        </FullTableHeader>

        <TableContainer>
          <Table>
            <tbody>
              {users &&
                users.map((user) => {
                  return (
                    <TableRow>
                      <TableData>{user.username}</TableData>
                      <TableData>{capitalizeFirstLetter(user.role)}</TableData>
                    </TableRow>
                  );
                })}

              <tr>
                <td
                  colSpan={5}
                  style={{ height: "200px", background: "transparent" }}
                />
              </tr>
            </tbody>
          </Table>
          {!users?.length && (
            <NoDataTableContainer>
              <GrCoatCheck />
              No Roles
            </NoDataTableContainer>
          )}
        </TableContainer>
      </RolesTableContainer>
    </PageContainer>
  );
};

export default Roles;
