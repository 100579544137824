import React, { useState, useRef, useEffect, useMemo } from "react";
import styled from "styled-components";
import CoatStatusDropdown from "./CoatStatusDropdown";

// Styled Components for Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100001;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  width: 40vw;
  height: 60vh;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
`;

const Title = styled.h3`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
`;

const RightHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const SearchInput = styled.input`
  width: 95px;
  font-size: 8px;
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TableContainer = styled.div`
  max-height: calc(60vh - 60px);
  overflow-y: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  border-top: 1px solid #eee;
`;

const TableHeaderContainer = styled.tr`
  width: 100%;
  box-shadow: inset 0 1px 0 #ccc, inset 0 -1px 0 #ccc; // Create inner top and bottom borders
  background-color: #f0f0f0;
  position: sticky; // Make the table header sticky
  top: 0; // Use dynamic height
  background-color: #f0f0f0; // Set background color to prevent overlap
  z-index: 2; // Ensure it stays above other content
`;

const TableHeader = styled.th`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  width: 100px; // Set a fixed width for the header
`;

const TableData = styled.td`
  padding: 18px 10px;
  font-size: 14px;
  width: 100px; // Set a fixed width for the data cells
`;

const NewModal = ({ onClose, allCoats }) => {
  const [data, setData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStausFilter] = useState("Status");
  const modalRef = useRef(null); // Create a ref for the dropdown

  useEffect(() => {
    const formattedData = Object.values(allCoats)
      .flat()
      .map((coat) => ({
        ...coat,
        status:
          Object.keys(allCoats)
            .find((key) => allCoats[key].includes(coat))
            .charAt(0)
            .toUpperCase() +
          Object.keys(allCoats)
            .find((key) => allCoats[key].includes(coat))
            .slice(1),
      }));
    setData(formattedData);
  }, [allCoats]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const filteredData = useMemo(() => {
    return data.filter((row) => {
      const matchesSearchQuery = searchQuery
        ? row.hangerId.includes(searchQuery) ||
          row.last4.includes(searchQuery) ||
          row.status.includes(searchQuery)
        : true;

      const matchesStatusFilter =
        statusFilter && statusFilter !== "Status"
          ? row.status === statusFilter
          : true;

      return matchesSearchQuery && matchesStatusFilter;
    });
  }, [data, searchQuery, statusFilter]);

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()} ref={modalRef}>
        <Header>
          <Title>100 TOTAL</Title>
          <RightHeaderContainer>
            <CoatStatusDropdown
              isOpen={isDropdownOpen}
              setIsOpen={setIsDropdownOpen}
              selectedItem={statusFilter}
              setSelectedItem={setStausFilter}
            />
            <SearchInput
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </RightHeaderContainer>
        </Header>

        <TableContainer>
          <Table>
            <thead>
              <TableHeaderContainer>
                <TableHeader>Tag #</TableHeader>
                <TableHeader>Last 4 Digits</TableHeader>
                <TableHeader>Status</TableHeader>
              </TableHeaderContainer>
            </thead>
            <tbody>
              {filteredData.map((row) => {
                return (
                  <TableRow key={row.hangerId}>
                    <TableData>{row.hangerId}</TableData>
                    <TableData>{row.last4}</TableData>
                    <TableData>{row.status}</TableData>
                  </TableRow>
                );
              })}
              {/* Repeat rows as needed */}
            </tbody>
          </Table>
        </TableContainer>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default NewModal;
