import styled from "styled-components";

export const CardSection = styled.section`
  display: flex;
  justify-content: space-around;
  padding: 15vh 0;
  background-color: #fff;
  position: relative;
  min-width: 50vh;

  @media screen and (max-width: 843px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 0;
    padding: 5vh 0;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  background-color: #184730;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 40px 30px;
  text-align: center;
  width: 15%; /* Adjust as needed */
  margin-right: -9%;
  position: absolute;
  height: 275px;

  @media screen and (max-width: 843px) {
    position: relative;
    width: 58%; /* Adjust as needed */
    margin-right: 0;
    margin-bottom: 35px;
  }

  @media screen and (max-width: 1338px) {
    height: 250px;
  }

  @media screen and (max-width: 1184px) {
    height: 275px;
    padding: 40px 30px;
  }

  @media screen and (min-width: 844px) {
    right: ${(props) => props.right};
    bottom: -160px;
  }

  &:hover {
    transform: translate(0px, -5px);
    transition: ease-in-out 0.2s;
  }
`;

export const CardHeading = styled.h3`
  font-size: 20px;
  color: #fff;
  font-family: Jost;
  font-weight: 700;

  @media screen and (max-width: 1338px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1070px) {
    font-size: 14px;
  }

  @media screen and (max-width: 936px) {
    font-size: 12px;
  }
`;

export const CardText = styled.p`
  font-size: 0.8rem;
  color: #fff;
  font-family: Jost;
  font-weight: 400;
  width: 85%;
  text-align: center;
  margin: auto;
`;
