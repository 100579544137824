import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/checkit-white.png";
import { authenticateBar } from "../utils/api/api-consumer";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #184730;
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0d2419;
  border-radius: 8px;
  padding: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 20%;
`;

const Logo = styled.img`
  width: 200px; /* Set your desired width for the logo */
  height: auto;
  margin-bottom: 50px;
`;

const Input = styled.input`
  width: 90%;
  padding: 7px 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: Jost;
`;

const Button = styled.button`
  width: 50%;
  padding: 10px;
  background-color: #184730;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-family: Jost;

  &:hover {
    background-color: #93aca0;
    transition: ease-in-out 0.2s;
  }
`;

const Message = styled.div`
  margin-top: 15px;
  font-size: 10px;
  font-family: Jost;
  color: white;
`;

const Login = () => {
  const [barId, setBarId] = useState("");
  const [password, setPassword] = useState("");
  const [failMessage, setFailMessage] = useState("");
  const navigate = useNavigate();

  const handleBarIdChange = (e) => {
    const barIdText = e.target.value;
    setBarId(barIdText);
  };

  const handlePasswordChange = (e) => {
    const passwordText = e.target.value;
    setPassword(passwordText);
  };

  const handleSubmit = async (barId, password) => {
    const response = await authenticateBar({ barId, password });

    if (response.status === "success") {
      navigate(`/bars/?id=${response.barId}`);
    } else {
      setFailMessage(response.message);
    }
  };

  return (
    <Container>
      <LoginForm>
        <Logo src={logo} alt="Logo" />
        <Input
          type="text"
          placeholder="Username"
          value={barId}
          onChange={handleBarIdChange}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button onClick={() => handleSubmit(barId, password)}>Login</Button>
        {failMessage.length ? <Message>{failMessage}</Message> : null}
      </LoginForm>
    </Container>
  );
};

export default Login;
