import { useEffect } from "react";
import { useUser } from "../context/UserContext"; // Adjust the import based on your context file
import { useNavigate, useLocation } from "react-router-dom";

const useAuth = () => {
  const { setBarId, setUserId } = useUser();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Check if the current path is /submit or /request
    if (
      location.pathname.includes("/submit") ||
      location.pathname.includes("/request")
    ) {
      return; // Skip authentication
    }

    const storedSession = localStorage.getItem("sessionData");
    if (storedSession) {
      const { barId, expirationTime, user } = JSON.parse(storedSession);
      const currentTime = new Date().getTime();
      if (currentTime < expirationTime) {
        setUserId(user);
        setBarId(barId);
      } else {
        // Clear expired session
        localStorage.removeItem("sessionData");
        setBarId(null);
        navigate("/newlogin"); // Redirect to login page
      }
    } else {
      if (!location.pathname.includes("/newlogin")) {
        navigate("/newlogin"); // Redirect to login page if no session
      }
    }
  }, [setBarId, navigate, location, setUserId]);
};

export default useAuth;
