import "./App.css";
import SubmitPhoneNumber from "./components/SubmitPhoneNumber";
import Login from "./components/Login";
import CoatCheckDisplay from "./components/CoatCheckDisplay";
import { Routes, Route } from "react-router-dom";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Website from "./website/Website";
import SessionPage from "./components/NewCoatCheck";
import Sidebar from "./components/Sidebar";
import Sessions from "./components/Sessions";
import SessionInfo from "./components/SessionInfo";
import TagsPage from "./components/TagsPage";
import { UserProvider } from "./context/UserContext";
import { useUser } from "./context/UserContext";
import LoginPage from "./components/NewLogin";
import NotFound from "./components/NotFound";
import Dashboard from "./components/Dashboard";
import Roles from "./components/Roles";
import useAuth from "./auth/useAuth";
import NewSubmit from "./components/NewSubmit";
import NewRequest from "./components/NewRequest";

function App() {
  return (
    <UserProvider>
      <Main />
    </UserProvider>
  );
}

const Main = () => {
  const { barId } = useUser(); // Access barId from UserContext
  useAuth();

  return (
    <div className="App">
      {barId && <Sidebar />}
      <div style={{ marginLeft: barId ? "60px" : "0" }}>
        <Routes>
          <Route exact path="/" element={<SubmitPhoneNumber />} />
          <Route path="/submit" element={<NewSubmit />} />
          <Route path="/login" element={<Login />} />
          <Route path="/bars" element={<CoatCheckDisplay />} />
          <Route path="/request" element={<NewRequest />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/info" element={<Website />} />
          <Route path="/newlogin" element={<LoginPage />} />
          <Route path="/session" element={<SessionPage sessionId={"1"} />} />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/session-info" element={<SessionInfo />} />
          <Route path="/tags" element={<TagsPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/roles" element={<Roles />} />
        </Routes>
      </div>
    </div>
  );
};

// function App() {
//   const { barId } = useUser(); // Access barId from UserContext
//   return (
//     <div className="App">
//       <UserProvider>
//         {barId && <Sidebar />}
//         <div style={{ marginLeft: "60px" }}>
//           <Routes>
//             <Route exact path="/" element={<SubmitPhoneNumber />} />
//             <Route path="/login" element={<Login />} />
//             <Route path="/bars" element={<CoatCheckDisplay />} />
//             <Route path="/request" element={<RequestCoat />} />
//             <Route path="/termsofuse" element={<TermsOfUse />} />
//             <Route path="/privacypolicy" element={<PrivacyPolicy />} />
//             <Route path="/info" element={<Website />} />
//             <Route path="/newlogin" element={<LoginPage />} />
//             {/* Need to change sessionId to be legit */}
//             <Route path="/test" element={<SessionPage sessionId={"1"} />} />
//             <Route path="/sessions" element={<Sessions />} />
//           </Routes>
//         </div>
//       </UserProvider>
//     </div>
//   );
// }

export default App;
