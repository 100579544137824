import styled from "styled-components";

export const CTASection = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 37vh;
  padding-bottom: 150px;

  @media screen and (max-width: 843px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;

export const CTAInfo = styled.div`
  max-width: 40%; /* Adjust as needed */
  margin-top: ;
  text-align: left;

  @media screen and (max-width: 843px) {
    max-width: 80%; /* Adjust as needed */
    padding: 0px;
  }
`;

export const CTAHeading = styled.h2`
  font-size: 64px;
  font-family: Jost;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;

  @media screen and (max-width: 843px) {
    font-size: 34px;
    text-align: center;
    margin-bottom: 60px;
  }
`;

export const CTASubHeading = styled.p`
  font-size: 26px;
  font-family: Jost;
  color: #fff;
  font-weight: 500;

  @media screen and (max-width: 843px) {
    margin-bottom: 80px;
  }
`;

export const CTABtn = styled.button`
  border-radius: 12px;
  border: none;
  padding: 15px 30px;
  background-color: #fff;
  color: #184730;
  font-size: 18px;
  cursor: pointer;
  height: 60px;
  font-family: Jost;
  font-weight: 700;

  &:hover {
    background-color: #0c2618;
    transition: ease-in-out 0.2s;
    color: #fff;
  }
`;
