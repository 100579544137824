import styled from "styled-components";

export const InfoSection = styled.section`
  text-align: center;
  padding-top: 50px;
  background-color: #fff;
  padding-bottom: 80px;

  @media screen and (max-width: 843px) {
    padding-top: 25px;
  }
`;

export const InfoHeading = styled.h2`
  font-size: 64px;
  font-family: Jost;
  color: #184730;
  margin-bottom: 15px;

  @media screen and (max-width: 843px) {
    font-size: 34px;
  }
`;

export const InfoSubHeading = styled.p`
  font-size: 18px;
  width: 55%;
  text-align: center;
  margin: auto;
  font-family: Jost;
  color: #184730;
  font-weight: 500;

  @media screen and (max-width: 843px) {
    width: 90%;
    padding: 0px 20px;
    font-size: 17px;
    text-align: center;
    margin-top: 40px;
  }
`;
