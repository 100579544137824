import styled from "styled-components";

export const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  height: 90vh;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (max-width: 843px) {
    height: 60vh;
  }
`;

export const HeroHeading = styled.h1`
  font-size: 64px;
  color: white;
  font-family: Jost;
  font-weight: 700;
  margin-bottom: 10px;

  @media screen and (max-width: 843px) {
    font-size: 34px;
  }
`;

export const HeroSubHeading = styled.p`
  font-size: 20px;
  color: white;
  font-family: Jost;
  margin: 0px;

  @media screen and (max-width: 843px) {
    font-size: 14px;
    max-width: 300px;
  }
`;
