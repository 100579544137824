import styled from "styled-components";

export const Footer = styled.div`
  text-align: center;
  padding: 20px 0;
  /* Adjust as needed */
  display: flex;
  align-items: center;
  background-color: #0c2618;
  width: 100%;
`;

export const FooterLogo = styled.img`
  max-height: 50px; /* Adjust as needed */
  margin: 20px 80px;

  @media screen and (max-width: 843px) {
    max-height: 30px; /* Adjust as needed */
    margin: 20px 40px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-family: Jost;

  @media screen and (max-width: 843px) {
    margin-top: 0px;
  }
`;

export const FooterLink = styled.a`
  margin: 0 10px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;

  @media screen and (max-width: 843px) {
    font-size: 12px;
  }
`;
