import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai";
import SmallLogo from "../assets/checkit-new-small.png";
import { authenticateUser } from "../utils/api/api-consumer";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { barId, setUserId, setBarId } = useUser();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    const response = await authenticateUser(email, password);
    if (response) {
      const barId = response[0].id;
      setBarId(barId);
      setUserId({ barName: response[0].name, username: email });
      const expirationDuration = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
      const expirationTime = new Date().getTime() + expirationDuration;

      // Store barId and expiration time in a single object in local storage
      const sessionData = {
        barId,
        expirationTime,
        user: { barName: response[0].name, username: email },
      };
      localStorage.setItem("sessionData", JSON.stringify(sessionData));
      navigate("/sessions");
    } else {
      console.log("Error authenticating user");
    }
  };

  useEffect(() => {
    if (barId) {
      navigate("/sessions");
    }
  }, [barId, navigate]);

  return (
    <Container>
      <LogoContainer>
        <Logo src={SmallLogo} alt="Logo" />
      </LogoContainer>
      <FormWrapper>
        <Title>Welcome back</Title>
        <Form onSubmit={handleLogin}>
          <InputWrapper>
            <IconWrapper>
              <AiOutlineMail />
            </IconWrapper>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <IconWrapper>
              <AiOutlineLock />
            </IconWrapper>
            <Input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputWrapper>
          <ForgotPassword>Forgot your password?</ForgotPassword>
          <LoginButton type="submit">Log In</LoginButton>
          <SignupLink>
            Don’t have an account? <span>Sign up</span>
          </SignupLink>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  flex-direction: column;
  gap: 20px;
`;

const FormWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

const Title = styled.div`
  margin: 10px 0px 30px;
  font-size: 26px;
  font-weight: 600;
  width: 80%;
  text-align: left;
  color: #515151;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 5px;
  //   background-color: #f7f7f7;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 0.9rem; /* Adjusted font size for placeholder text */
  padding: 0.5rem 0;
  background: none;

  /* Prevents the blue background on autofill */
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
    box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
  }
`;

const ForgotPassword = styled.div`
  text-align: left;
  color: #006400;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 1.2rem;
  cursor: pointer;
`;

const LoginButton = styled.button`
  padding: 0.75rem;
  background-color: #004d33;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #003322;
  }
`;

const SignupLink = styled.p`
  font-size: 0.9rem;
  color: #555;

  span {
    color: #006400;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;
