// ConfirmationModal.js
import React, { useEffect, useRef } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import styled from "styled-components";
import { BeatLoader } from "react-spinners";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 25px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 60vw;
  max-width: 500px;
`;

const PhoneNumber = styled.p`
  font-size: 20px;
  margin: 40px 0;
  color: #515151;
  font-weight: 600;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  background-color: #184730; /* Adjust color as needed */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  height: 36px;

  &:hover {
    background-color: #245b3a; /* Darker shade for hover */
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
`;

const ConfirmSubmitModal = ({
  isLoading,
  onClose,
  phoneNumber,
  onSubmit,
  didCheck,
}) => {
  const modalRef = useRef(null); // Create a ref for the dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <ModalOverlay>
      <ModalContainer ref={modalRef}>
        <div
          style={{
            fontWeight: "bold",
            color: "#656565",
            fontSize: "22px",
            width: "100%",
            textAlign: "left",
          }}
        >
          {didCheck === null
            ? "Please confirm"
            : didCheck === false
            ? "Oops"
            : "Success"}
        </div>
        <Divider />
        <PhoneNumber>
          {didCheck === null
            ? phoneNumber
            : didCheck === false
            ? "Please try again or speak\nwith the coat check host."
            : "Expect SMS\nconfirmation shortly."}
        </PhoneNumber>
        <Button disabled={didCheck !== null} onClick={onSubmit}>
          <ButtonContent>
            {isLoading ? (
              <BeatLoader color="white" size={8} />
            ) : didCheck === null ? (
              <>
                Submit
                <AiOutlineArrowRight />
              </>
            ) : didCheck === false ? (
              <AiOutlineCloseCircle />
            ) : (
              <AiOutlineCheckCircle />
            )}
          </ButtonContent>
        </Button>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ConfirmSubmitModal;
