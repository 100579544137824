import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, Tooltip } from "recharts";
import PropTypes from "prop-types";

const DoubleBarChart = ({
  // Data props
  data,
  firstBarKey,
  secondBarKey,
  xAxisKey,

  // Sizing props
  width = "90%",
  height = 300,
  margin = { left: 20, right: 20, top: 20 },

  // Bar styling
  firstBarColor = "#184730",
  secondBarColor = "#ca4040",
  firstBarName = "First Bar",
  secondBarName = "Second Bar",
  barRadius = [1, 1, 0, 0],

  // X-Axis styling
  xAxisInterval = 0,
  xAxisAngle = 0,
  xAxisHeight = 60,
  xAxisFontSize = 12,
  xAxisColor = "#a1a1a1",
  xAxisLabelOffsetX = 25,
  xAxisLabelOffsetY = 15,

  // Tooltip styling
  tooltipBackground = "white",
  tooltipBorder = "1px solid #d1d1d1",
  tooltipBorderRadius = "4px",
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart data={data} margin={margin}>
        <XAxis
          dataKey={xAxisKey}
          interval={xAxisInterval}
          angle={xAxisAngle}
          textAnchor="end"
          height={xAxisHeight}
          tick={{
            fontSize: xAxisFontSize,
            fill: xAxisColor,
            dx: xAxisLabelOffsetX,
            dy: xAxisLabelOffsetY,
          }}
        />
        <Tooltip
          cursor={false}
          contentStyle={{
            backgroundColor: tooltipBackground,
            border: tooltipBorder,
            borderRadius: tooltipBorderRadius,
          }}
        />
        <Bar
          dataKey={firstBarKey}
          fill={firstBarColor}
          name={firstBarName}
          radius={barRadius}
        />
        <Bar
          dataKey={secondBarKey}
          fill={secondBarColor}
          name={secondBarName}
          radius={barRadius}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

DoubleBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  firstBarKey: PropTypes.string.isRequired,
  secondBarKey: PropTypes.string.isRequired,
  xAxisKey: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  margin: PropTypes.object,
  firstBarColor: PropTypes.string,
  secondBarColor: PropTypes.string,
  firstBarName: PropTypes.string,
  secondBarName: PropTypes.string,
  barRadius: PropTypes.arrayOf(PropTypes.number),
  xAxisInterval: PropTypes.number,
  xAxisAngle: PropTypes.number,
  xAxisHeight: PropTypes.number,
  xAxisFontSize: PropTypes.number,
  xAxisColor: PropTypes.string,
  xAxisLabelOffsetX: PropTypes.number,
  xAxisLabelOffsetY: PropTypes.number,
  tooltipBackground: PropTypes.string,
  tooltipBorder: PropTypes.string,
  tooltipBorderRadius: PropTypes.string,
};

export default DoubleBarChart;
