import axios from "axios";
import {
  sessionData,
  tagData,
  addSession,
  demoUsers,
  allSessionsCoats,
} from "../demoData";

// const BASE_URL = "https://8p01ujkad8.execute-api.us-east-2.amazonaws.com";
const BASE_URL = "http://localhost:8080";

const devBarID = "186898";

const StatusEnum = {
  coatChecked: "CoatChecked",
  pendingPickup: "PendingPickup",
};

const phoneNumberFormatter = (phoneNum) => {
  return phoneNum.trim().replace(/[^0-9]/g, "");
};

/*
  Params Structure
  {
    hangerId: string; - Ex: PT-12345
    phoneNum: string;
    coatStatus: StatusEnum
  }
*/
const putRequestBody = (params) => {
  return {
    hangerId: params.hangerId,
    phoneNum: phoneNumberFormatter(params.phoneNumber),
    coatStatus: StatusEnum[params.status],
  };
};

const patchRequestBody = (params) => {
  return {
    hangerId: params.hangerId,
    coatStatus: StatusEnum[params.status],
  };
};

export const createNewSession = async (params) => {
  try {
    const response = await axios.put(`${BASE_URL}/sessions`, {
      ...putRequestBody(params),
    });

    if (response.status === 200) {
      return "success";
    } else {
      return "failed";
    }
  } catch (error) {
    console.log(error);

    return "failed";
  }
};

export const updateSession = async (params) => {
  try {
    const response = await axios.patch(`${BASE_URL}/sessions`, {
      ...patchRequestBody(params),
    });

    return response.data;
  } catch (error) {
    console.log(error);

    return error.response;
  }
};

/*
    Params structure
    {
        barId: string;
        password: string;
    }
*/
export const authenticateBar = async (params) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/bars/${params.barId}`);

    if (data.password === params.password) {
      return {
        status: "success",
        barId: data.barId,
      };
    } else {
      return {
        status: "failed",
        message: "The Username/Password entered don't match our records.",
      };
    }
  } catch (error) {
    return {
      status: "failed",
      message: "Something went wrong. Please try again.",
    };
  }
};

export const deleteSession = async (hid) => {
  try {
    const response = await axios.delete(`${BASE_URL}/sessions/${hid}`);

    if (response.status !== 200) {
      alert("Something went wrong... Please try again");
      return "failed";
    }

    return "success";
  } catch (error) {
    return "failed";
  }
};

export const checkoutRedirect = async (hid) => {
  try {
    const res = await axios.patch(`${BASE_URL}/sessions`, {
      hangerId: hid,
      coatStatus: StatusEnum.pendingPickup,
    });

    return res.status;
  } catch (error) {
    return;
  }
};

export const getCoatsBySessionId = async (sessionId) => {
  try {
    const res = await axios.get(`${BASE_URL}/coats/${sessionId}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSessionsWithID = async (sessionId) => {
  try {
    const res = await axios.get(`${BASE_URL}/coats/${sessionId}`);
    // revisit this bc I don't know whether .body.data is the array of elements
    // return res.data.filter(
    //   (session) => session.hangerId.split("-")[0] === barId
    // );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateTriagedAtTimestamp = async (sessionId, hangerId) => {
  try {
    const res = await axios.post(`${BASE_URL}/coats/triaged-at`, {
      sessionId,
      hangerId,
    });

    return res.status;
  } catch (err) {
    console.log("Error when trying to update the triaged_at timestamp", err);
  }
};

export const updateFulfilledAtTimestamp = async (sessionId, hangerId) => {
  try {
    const res = await axios.post(`${BASE_URL}/coats/request-fulfilled-at`, {
      sessionId,
      hangerId,
    });

    return res.status;
  } catch (err) {
    console.log(
      "Error when trying to update the request fulfilled at timestamp",
      err
    );
  }
};

export const createSession = async (barId, name) => {
  console.log(barId);
  console.log(name);
  if (barId === devBarID) {
    const newSession = addSession(name);
    return { sessionId: newSession.id };
  }

  try {
    const res = await axios.post(`${BASE_URL}/sessions`, {
      barId,
      name,
    });

    if (res.status > 299) {
      return "Error creating the session";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to create a new session", err);
    return "Session in progress";
  }
};

export const endSession = async (sessionId) => {
  try {
    const res = await axios.post(`${BASE_URL}/sessions/complete`, {
      sessionId,
    });

    if (res.status > 299) {
      return "Error ending the session";
    }

    return;
  } catch (err) {
    console.log("Error when trying to end the session", err);
  }
};

export const getSessions = async (barId) => {
  if (barId === devBarID) {
    return sessionData[barId];
  }
  try {
    const res = await axios.get(`${BASE_URL}/sessions/barId/${barId}`);

    if (res.status > 299) {
      return "Error getting sessions";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to get sessions", err);
  }
};

export const getSessionById = async (sessionId) => {
  try {
    const res = await axios.get(`${BASE_URL}/sessions/${sessionId}`);

    if (res.status > 299) {
      return "Error getting session";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to get session", err);
  }
};

export const getTags = async (barId) => {
  if (barId === devBarID) {
    return tagData;
  }
  try {
    const res = await axios.get(`${BASE_URL}/tags/${barId}`);

    if (res.status > 299) {
      return "Error getting tags";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to get tags", err);
  }
};

export const createTag = async (barId, label, color) => {
  try {
    const res = await axios.post(`${BASE_URL}/tags`, {
      barId,
      label,
      color,
    });

    if (res.status > 299) {
      return "Error creating the tags";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to create tags", err);
  }
};

export const setSessionTags = async (sessionId, tags) => {
  try {
    const res = await axios.post(`${BASE_URL}/sessions/add-tags`, {
      sessionId,
      tags,
    });

    if (res.status > 299) {
      return "Error setting the tags";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to set the tags", err);
  }
};

export const removeSessionTags = async (sessionId, tags) => {
  try {
    const res = await axios.post(`${BASE_URL}/sessions/remove-tags`, {
      sessionId,
      tags,
    });

    if (res.status > 299) {
      return "Error setting the tags";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to set the tags", err);
  }
};

export const authenticateUser = async (email, password) => {
  try {
    const res = await axios.post(`${BASE_URL}/users`, {
      email,
      password,
    });

    if (res.status > 299) {
      return "Error authenticating user";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to authenticate user", err);
  }
};

export const getUsersByBarId = async (barId) => {
  if (barId === devBarID) {
    return demoUsers;
  }
  try {
    const res = await axios.get(`${BASE_URL}/users/${barId}`);

    if (res.status > 299) {
      return "Error getting users";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to get users", err);
  }
};

export const getBarDashboardData = async (barId, from, to) => {
  if (barId === devBarID) {
    // const filteredData = {
    //   ...coatData2,
    //   [barId]: coatData2[barId].filter((coat) => {
    //     const coatDate = new Date(coat.checked_at);
    //     return coatDate >= new Date(from) && coatDate <= new Date(to);
    //   }),
    // };

    return allSessionsCoats;
  }
  try {
    const res = await axios.get(
      `${BASE_URL}/bars/getData/${barId}/?fromTimestamp=${from}&toTimestamp=${to}`
    );

    if (res.status > 299) {
      return "Error getting data";
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to get users", err);
  }
};

export const checkCoat = async (hangerId, phoneNumber) => {
  try {
    const res = await axios.post(`${BASE_URL}/coats`, {
      hangerId,
      phoneNumber,
    });

    if (res.status > 299) {
      throw new Error();
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to create coat", err);
    throw new Error();
  }
};

export const requestCoat = async (id) => {
  try {
    const res = await axios.post(`${BASE_URL}/coats/requested-at`, {
      id,
    });

    if (res.status > 299) {
      throw new Error();
    }

    return res.data;
  } catch (err) {
    console.log("Error when trying to request coat", err);
    throw new Error();
  }
};
