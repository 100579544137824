import styled from "styled-components";

export const NavBar = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(25, 71, 48, 0.9);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

export const Logo = styled.img`
  margin: 10px 40px;
  max-height: 30px; /* Adjust as needed */
`;

export const DemoButton = styled.button`
  border-radius: 7px;
  border: none;
  padding: 7px 20px;
  background-color: #fff; /* Adjust as needed */
  color: #184730;
  cursor: pointer;
  font-family: Jost;
  font-weight: 700;

  &:hover {
    background-color: #0c2618;
    transition: ease-in-out 0.1s;
    color: #fff;
  }

  @media screen and (max-width: 843px) {
    padding: 6px 16px;
    font-size: 12px;
  }
`;

export const ButtonWrapper = styled.a`
  text-decoration: none;
  margin-right: 5%;

  @media screen and (max-width: 843px) {
    margin-right: 8%;
  }
`;
