// Demo data
export const sessionData = {
  186898: [
    {
      bar_id: 186898,
      completed_at: "2024-11-18T22:00:00.000Z",
      created_at: "2024-10-18T18:01:45.106Z",
      id: "fa1412d4-ff13-4a74-b389-98bf3eb74f84",
      name: "November 18, 2024",
      tags: [
        {
          id: "1",
          created_at: "2024-10-18T18:01:45.106Z",
          bar_id: "186898",
          label: "demoTag",
          color: "#D3D3D3",
        },
      ],
    },
    {
      bar_id: 186898,
      completed_at: null,
      created_at: "2024-10-19T14:30:00.000Z",
      id: "2b9e4f8d-c123-45a6-b789-0123456789ab",
      name: "November 19, 2024",
      tags: [],
    },
    {
      bar_id: 186898,
      completed_at: "2024-10-20T22:30:00.000Z",
      created_at: "2024-10-20T09:15:30.500Z",
      id: "3c4d5e6f-7890-abcd-ef12-345678901234",
      name: "November 20, 2024",
      tags: [],
    },
    {
      bar_id: 186898,
      completed_at: "2024-10-21T23:15:00.000Z",
      created_at: "2024-10-21T11:45:00.000Z",
      id: "4a5b6c7d-8901-2345-6789-abcdef012345",
      name: "November 21, 2024",
      tags: [],
    },
  ],
};

export const tagData = [
  {
    id: "1",
    created_at: "2024-10-18T18:01:45.106Z",
    bar_id: "186898",
    label: "demoTag",
    color: "#D3D3D3",
    session_count: 1,
  },
  {
    id: "2",
    created_at: "2024-10-18T18:01:45.106Z",
    bar_id: "186898",
    label: "demoTag2",
    color: "#32B5CD",
    session_count: 0,
  },
  {
    id: "3",
    created_at: "2024-10-18T18:01:45.106Z",
    bar_id: "186898",
    label: "demoTag3",
    color: "#F6C8C2",
    session_count: 0,
  },
];

export const coatData = [
  {
    bar_id: "186898",
    checked_at: "2024-10-18T18:01:45.106Z",
    hanger_id: "1",
    id: "fa1412d4-ff13-4a74-b389-98bf3eb74f84",
    phone_number: "6175551234",
    request_fulfilled_at: "2024-10-18T22:15:00.000Z",
    requested_at: "2024-10-18T22:10:00.000Z",
    session_id: "1",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T18:15:22.304Z",
    hanger_id: "2",
    id: "fb2523e5-gg24-5b85-c490-09c4fc85g95",
    phone_number: "6175559876",
    request_fulfilled_at: "2024-10-18T23:45:00.000Z",
    requested_at: "2024-10-18T23:40:00.000Z",
    session_id: "1",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T18:30:15.892Z",
    hanger_id: "3",
    id: "fc3634f6-hh35-6c96-d501-10d5gd96h06",
    phone_number: "6175554321",
    request_fulfilled_at: "2024-10-19T01:20:00.000Z",
    requested_at: "2024-10-19T01:15:00.000Z",
    session_id: "1",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T18:45:33.567Z",
    hanger_id: "4",
    id: "fd4745g7-ii46-7d07-e612-21e6he07i17",
    phone_number: "6175557890",
    request_fulfilled_at: "2024-10-19T00:05:00.000Z",
    requested_at: "2024-10-19T00:00:00.000Z",
    session_id: "1",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T19:00:48.789Z",
    hanger_id: "5",
    id: "fe5856h8-jj57-8e18-f723-32f7if18j28",
    phone_number: "6175552468",
    request_fulfilled_at: "2024-10-18T21:35:00.000Z",
    requested_at: "2024-10-18T21:30:00.000Z",
    session_id: "1",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T19:15:12.345Z",
    hanger_id: "6",
    id: "ff6967i9-kk68-9f29-g834-43g8jg29k39",
    phone_number: "6175553579",
    request_fulfilled_at: "2024-10-19T02:50:00.000Z",
    requested_at: "2024-10-19T02:45:00.000Z",
    session_id: "2",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T19:30:25.678Z",
    hanger_id: "7",
    id: "fg7078j0-ll79-0g30-h945-54h9kh30l40",
    phone_number: "6175551357",
    request_fulfilled_at: "2024-10-19T01:05:00.000Z",
    requested_at: "2024-10-19T01:00:00.000Z",
    session_id: "2",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T19:45:36.901Z",
    hanger_id: "8",
    id: "fh8189k1-mm80-1h41-i056-65i0li41m51",
    phone_number: "6175559753",
    request_fulfilled_at: "2024-10-18T22:20:00.000Z",
    requested_at: "2024-10-18T22:15:00.000Z",
    session_id: "2",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T20:00:47.123Z",
    hanger_id: "9",
    id: "fi9290l2-nn91-2i52-j167-76j1mj52n62",
    phone_number: "6175558642",
    request_fulfilled_at: "2024-10-19T00:35:00.000Z",
    requested_at: "2024-10-19T00:30:00.000Z",
    session_id: "2",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T20:15:58.456Z",
    hanger_id: "10",
    id: "fj0301m3-oo02-3j63-k278-87k2nk63o73",
    phone_number: "6175557531",
    request_fulfilled_at: "2024-10-19T02:05:00.000Z",
    requested_at: "2024-10-19T02:00:00.000Z",
    session_id: "2",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T20:30:09.789Z",
    hanger_id: "11",
    id: "fk1412n4-pp13-4k74-l389-98l3ol74p84",
    phone_number: "6175556420",
    request_fulfilled_at: "2024-10-18T23:50:00.000Z",
    requested_at: "2024-10-18T23:45:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T20:45:20.012Z",
    hanger_id: "12",
    id: "fl2523o5-qq24-5l85-m490-09m4pm85q95",
    phone_number: "6175555309",
    request_fulfilled_at: "2024-10-19T01:35:00.000Z",
    requested_at: "2024-10-19T01:30:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T21:00:31.345Z",
    hanger_id: "13",
    id: "fm3634p6-rr35-6m96-n501-10n5qn96r06",
    phone_number: "6175554198",
    request_fulfilled_at: "2024-10-19T02:20:00.000Z",
    requested_at: "2024-10-19T02:15:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T21:15:42.678Z",
    hanger_id: "14",
    id: "fn4745q7-ss46-7n07-o612-21o6ro07s17",
    phone_number: "6175553087",
    request_fulfilled_at: "2024-10-18T22:50:00.000Z",
    requested_at: "2024-10-18T22:45:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T21:30:53.901Z",
    hanger_id: "15",
    id: "fo5856r8-tt57-8o18-p723-32p7sp18t28",
    phone_number: "6175551976",
    request_fulfilled_at: "2024-10-19T00:20:00.000Z",
    requested_at: "2024-10-19T00:15:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T21:45:04.234Z",
    hanger_id: "16",
    id: "fp6967s9-uu68-9p29-q834-43q8tq29u39",
    phone_number: "6175550865",
    request_fulfilled_at: "2024-10-19T01:50:00.000Z",
    requested_at: "2024-10-19T01:45:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T22:00:15.567Z",
    hanger_id: "17",
    id: "fq7078t0-vv79-0q30-r945-54r9ur30v40",
    phone_number: "6175559754",
    request_fulfilled_at: "2024-10-19T02:35:00.000Z",
    requested_at: "2024-10-19T02:30:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T22:15:26.890Z",
    hanger_id: "18",
    id: "fr8189u1-ww80-1r41-s056-65s0vs41w51",
    phone_number: "6175558643",
    request_fulfilled_at: "2024-10-18T23:05:00.000Z",
    requested_at: "2024-10-18T23:00:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T22:30:37.123Z",
    hanger_id: "19",
    id: "fs9290v2-xx91-2s52-t167-76t1wt52x62",
    phone_number: "6175557532",
    request_fulfilled_at: "2024-10-19T00:50:00.000Z",
    requested_at: "2024-10-19T00:45:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T22:45:48.456Z",
    hanger_id: "20",
    id: "ft0301w3-yy02-3t63-u278-87u2xu63y73",
    phone_number: "6175556421",
    request_fulfilled_at: "2024-10-19T02:05:00.000Z",
    requested_at: "2024-10-19T02:00:00.000Z",
    session_id: "3",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T23:00:59.789Z",
    hanger_id: "21",
    id: "fu1412x4-zz13-4u74-v389-98v3yv74z84",
    phone_number: "6175555310",
    request_fulfilled_at: "2024-10-19T01:20:00.000Z",
    requested_at: "2024-10-19T01:15:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T23:16:10.012Z",
    hanger_id: "22",
    id: "fv2523y5-aa24-5v85-w490-09w4zw85a95",
    phone_number: "6175554199",
    request_fulfilled_at: "2024-10-19T02:35:00.000Z",
    requested_at: "2024-10-19T02:30:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T23:31:21.345Z",
    hanger_id: "23",
    id: "fw3634z6-bb35-6w96-x501-10x5ax96b06",
    phone_number: "6175553088",
    request_fulfilled_at: "2024-10-19T01:50:00.000Z",
    requested_at: "2024-10-19T01:45:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-18T23:46:32.678Z",
    hanger_id: "24",
    id: "fx4745a7-cc46-7x07-y612-21y6by07c17",
    phone_number: "6175551977",
    request_fulfilled_at: "2024-10-19T02:20:00.000Z",
    requested_at: "2024-10-19T02:15:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T00:01:43.901Z",
    hanger_id: "25",
    id: "fy5856b8-dd57-8y18-z723-32z7cz18d28",
    phone_number: "6175550866",
    request_fulfilled_at: "2024-10-19T02:50:00.000Z",
    requested_at: "2024-10-19T02:45:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T00:16:54.234Z",
    hanger_id: "26",
    id: "fz6967c9-ee68-9z29-a834-43a8da29e39",
    phone_number: "6175559755",
    request_fulfilled_at: "2024-10-19T01:35:00.000Z",
    requested_at: "2024-10-19T01:30:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T00:32:05.567Z",
    hanger_id: "27",
    id: "ga7078d0-ff79-0a30-b945-54b9eb30f40",
    phone_number: "6175558644",
    request_fulfilled_at: "2024-10-19T02:05:00.000Z",
    requested_at: "2024-10-19T02:00:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T00:47:16.890Z",
    hanger_id: "28",
    id: "gb8189e1-gg80-1b41-c056-65c0fc41g51",
    phone_number: "6175557533",
    request_fulfilled_at: "2024-10-19T02:35:00.000Z",
    requested_at: "2024-10-19T02:30:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T01:02:27.123Z",
    hanger_id: "29",
    id: "gc9290f2-hh91-2c52-d167-76d1gd52h62",
    phone_number: "6175556422",
    request_fulfilled_at: "2024-10-19T01:50:00.000Z",
    requested_at: "2024-10-19T01:45:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T01:17:38.456Z",
    hanger_id: "30",
    id: "gd0301g3-ii02-3d63-e278-87e2he63i73",
    phone_number: "6175555311",
    request_fulfilled_at: "2024-10-19T02:20:00.000Z",
    requested_at: "2024-10-19T02:15:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T01:32:49.789Z",
    hanger_id: "31",
    id: "ge1412h4-jj13-4e74-f389-98f3if74j84",
    phone_number: "6175554200",
    request_fulfilled_at: "2024-10-19T02:50:00.000Z",
    requested_at: "2024-10-19T02:45:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T01:48:00.012Z",
    hanger_id: "32",
    id: "gf2523i5-kk24-5f85-g490-09g4jg85k95",
    phone_number: "6175553089",
    request_fulfilled_at: "2024-10-19T02:35:00.000Z",
    requested_at: "2024-10-19T02:30:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T02:03:11.345Z",
    hanger_id: "33",
    id: "gg3634j6-ll35-6g96-h501-10h5kh96l06",
    phone_number: "6175551978",
    request_fulfilled_at: "2024-10-19T02:20:00.000Z",
    requested_at: "2024-10-19T02:15:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T02:18:22.678Z",
    hanger_id: "34",
    id: "gh4745k7-mm46-7h07-i612-21i6li07m17",
    phone_number: "6175550867",
    request_fulfilled_at: "2024-10-19T02:50:00.000Z",
    requested_at: "2024-10-19T02:45:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T02:33:33.901Z",
    hanger_id: "35",
    id: "gi5856l8-nn57-8i18-j723-32j7mj18n28",
    phone_number: "6175559756",
    request_fulfilled_at: "2024-10-19T02:50:00.000Z",
    requested_at: "2024-10-19T02:45:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T02:48:44.234Z",
    hanger_id: "36",
    id: "gj6967m9-oo68-9j29-k834-43k8nk29o39",
    phone_number: "6175558645",
    request_fulfilled_at: "2024-10-19T02:55:00.000Z",
    requested_at: "2024-10-19T02:50:00.000Z",
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T03:03:55.567Z",
    hanger_id: "37",
    id: "gk7078n0-pp79-0k30-l945-54l9ol30p40",
    phone_number: "6175557534",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T03:19:06.890Z",
    hanger_id: "38",
    id: "gl8189o1-qq80-1l41-m056-65m0pm41q51",
    phone_number: "6175556423",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T03:34:17.123Z",
    hanger_id: "39",
    id: "gm9290p2-rr91-2m52-n167-76n1qn52r62",
    phone_number: "6175555312",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T03:49:28.456Z",
    hanger_id: "40",
    id: "gn0301q3-ss02-3n63-o278-87o2ro63s73",
    phone_number: "6175554201",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T04:04:39.789Z",
    hanger_id: "41",
    id: "go1412r4-tt13-4o74-p389-98p3sp74t84",
    phone_number: "6175553090",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T04:19:50.012Z",
    hanger_id: "42",
    id: "gp2523s5-uu24-5p85-q490-09q4tq85u95",
    phone_number: "6175551979",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T04:35:01.345Z",
    hanger_id: "43",
    id: "gq3634t6-vv35-6q96-r501-10r5ur96v06",
    phone_number: "6175550868",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T04:50:12.678Z",
    hanger_id: "44",
    id: "gr4745u7-ww46-7r07-s612-21s6vs07w17",
    phone_number: "6175559757",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T05:05:23.901Z",
    hanger_id: "45",
    id: "gs5856v8-xx57-8s18-t723-32t7wt18x28",
    phone_number: "6175558646",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T05:20:34.234Z",
    hanger_id: "46",
    id: "gt6967w9-yy68-9t29-u834-43u8xu29y39",
    phone_number: "6175557535",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T05:35:45.567Z",
    hanger_id: "47",
    id: "gu7078x0-zz79-0u30-v945-54v9yv30z40",
    phone_number: "6175556424",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T05:50:56.890Z",
    hanger_id: "48",
    id: "gv8189y1-aa80-1v41-w056-65w0zw41a51",
    phone_number: "6175555313",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T06:06:07.123Z",
    hanger_id: "49",
    id: "gw9290z2-bb91-2w52-x167-76x1ax52b62",
    phone_number: "6175554202",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: true,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T06:21:18.456Z",
    hanger_id: "50",
    id: "gx0301a3-cc02-3x63-y278-87y2by63c73",
    phone_number: "6175553091",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
    has_checked_before: false,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T06:36:29.789Z",
    hanger_id: "51",
    id: "gy1412b4-dd13-4y74-z389-98z3cz74d84",
    phone_number: "6175551980",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T06:51:40.012Z",
    hanger_id: "52",
    id: "gz2523c5-ee24-5z85-a490-09a4da85e95",
    phone_number: "6175550869",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T07:06:51.345Z",
    hanger_id: "53",
    id: "ha3634d6-ff35-6a96-b501-10b5eb96f06",
    phone_number: "6175559758",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T07:22:02.678Z",
    hanger_id: "54",
    id: "hb4745e7-gg46-7b07-c612-21c6fc07g17",
    phone_number: "6175558647",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
  },
  {
    bar_id: "186898",
    checked_at: "2024-10-19T07:37:13.901Z",
    hanger_id: "55",
    id: "hc5856f8-hh57-8c18-d723-32d7gd18h28",
    phone_number: "6175557536",
    request_fulfilled_at: null,
    requested_at: null,
    session_id: "4",
    triaged_at: null,
  },
];

export const coatData2 = {
  186898: [
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:01:45.106Z",
      hanger_id: "1",
      id: "fa1412d4-ff13-4a74-b389-98bf3eb74f84",
      phone_number: "6175551234",
      request_fulfilled_at: "2024-10-19T01:15:00.000Z",
      requested_at: "2024-10-19T01:10:00.000Z",
      session_id: "1",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:05:22.304Z",
      hanger_id: "2",
      id: "fb2523e5-gg24-5b85-c490-09c4fc85g95",
      phone_number: "6175559876",
      request_fulfilled_at: "2024-10-19T01:45:00.000Z",
      requested_at: "2024-10-19T01:40:00.000Z",
      session_id: "1",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:10:15.892Z",
      hanger_id: "3",
      id: "fc3634f6-hh35-6c96-d501-10d5gd96h06",
      phone_number: "6175554321",
      request_fulfilled_at: "2024-10-19T02:20:00.000Z",
      requested_at: "2024-10-19T02:15:00.000Z",
      session_id: "1",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:15:33.567Z",
      hanger_id: "4",
      id: "fd4745g7-ii46-7d07-e612-21e6he07i17",
      phone_number: "6175557890",
      request_fulfilled_at: "2024-10-19T02:05:00.000Z",
      requested_at: "2024-10-19T02:00:00.000Z",
      session_id: "1",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:20:48.789Z",
      hanger_id: "5",
      id: "fe5856h8-jj57-8e18-f723-32f7if18j28",
      phone_number: "6175552468",
      request_fulfilled_at: "2024-10-19T02:35:00.000Z",
      requested_at: "2024-10-19T02:30:00.000Z",
      session_id: "1",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:25:12.345Z",
      hanger_id: "6",
      id: "ff6967i9-kk68-9f29-g834-43g8jg29k39",
      phone_number: "6175553579",
      request_fulfilled_at: "2024-10-19T02:50:00.000Z",
      requested_at: "2024-10-19T02:45:00.000Z",
      session_id: "2",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:30:25.678Z",
      hanger_id: "7",
      id: "fg7078j0-ll79-0g30-h945-54h9kh30l40",
      phone_number: "6175551357",
      request_fulfilled_at: "2024-10-19T01:05:00.000Z",
      requested_at: "2024-10-19T01:00:00.000Z",
      session_id: "2",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:35:36.901Z",
      hanger_id: "8",
      id: "fh8189k1-mm80-1h41-i056-65i0li41m51",
      phone_number: "6175559753",
      request_fulfilled_at: "2024-10-19T02:20:00.000Z",
      requested_at: "2024-10-19T02:15:00.000Z",
      session_id: "2",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:40:47.123Z",
      hanger_id: "9",
      id: "fi9290l2-nn91-2i52-j167-76j1mj52n62",
      phone_number: "6175558642",
      request_fulfilled_at: "2024-10-19T02:35:00.000Z",
      requested_at: "2024-10-19T02:30:00.000Z",
      session_id: "2",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T18:45:58.456Z",
      hanger_id: "10",
      id: "fj0301m3-oo02-3j63-k278-87k2nk63o73",
      phone_number: "6175557531",
      request_fulfilled_at: "2024-10-19T02:05:00.000Z",
      requested_at: "2024-10-19T02:00:00.000Z",
      session_id: "2",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T19:00:59.789Z",
      hanger_id: "11",
      id: "fk1412n4-pp13-4k74-l389-98l3ol74p84",
      phone_number: "6175556420",
      request_fulfilled_at: "2024-10-19T01:50:00.000Z",
      requested_at: "2024-10-19T01:45:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T19:15:10.012Z",
      hanger_id: "12",
      id: "fl2523o5-qq24-5l85-m490-09m4pm85q95",
      phone_number: "6175555309",
      request_fulfilled_at: "2024-10-19T02:35:00.000Z",
      requested_at: "2024-10-19T02:30:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T19:30:21.345Z",
      hanger_id: "13",
      id: "fm3634p6-rr35-6m96-n501-10n5qn96r06",
      phone_number: "6175554198",
      request_fulfilled_at: "2024-10-19T02:20:00.000Z",
      requested_at: "2024-10-19T02:15:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T19:45:32.678Z",
      hanger_id: "14",
      id: "fn4745q7-ss46-7n07-o612-21o6ro07s17",
      phone_number: "6175553087",
      request_fulfilled_at: "2024-10-19T02:50:00.000Z",
      requested_at: "2024-10-19T02:45:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T20:00:43.901Z",
      hanger_id: "15",
      id: "fo5856r8-tt57-8o18-p723-32p7sp18t28",
      phone_number: "6175551976",
      request_fulfilled_at: "2024-10-19T02:20:00.000Z",
      requested_at: "2024-10-19T02:15:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T20:30:54.234Z",
      hanger_id: "16",
      id: "fp6967s9-uu68-9z29-a834-43a8da29e39",
      phone_number: "6175550865",
      request_fulfilled_at: "2024-10-19T01:50:00.000Z",
      requested_at: "2024-10-19T01:45:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T21:00:15.567Z",
      hanger_id: "17",
      id: "fq7078t0-vv79-0a30-b945-54b9eb30f40",
      phone_number: "6175559754",
      request_fulfilled_at: "2024-10-19T02:35:00.000Z",
      requested_at: "2024-10-19T02:30:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T21:30:26.890Z",
      hanger_id: "18",
      id: "fr8189u1-ww80-1r41-s056-65s0vs41w51",
      phone_number: "6175558643",
      request_fulfilled_at: "2024-10-19T02:05:00.000Z",
      requested_at: "2024-10-19T02:00:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T22:00:37.123Z",
      hanger_id: "19",
      id: "fs9290v2-xx91-2s52-t167-76t1wt52x62",
      phone_number: "6175557532",
      request_fulfilled_at: "2024-10-19T02:50:00.000Z",
      requested_at: "2024-10-19T02:45:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T22:30:48.456Z",
      hanger_id: "20",
      id: "ft0301w3-yy02-3t63-u278-87u2xu63y73",
      phone_number: "6175556421",
      request_fulfilled_at: "2024-10-19T02:05:00.000Z",
      requested_at: "2024-10-19T02:00:00.000Z",
      session_id: "3",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T23:00:59.789Z",
      hanger_id: "21",
      id: "fu1412x4-zz13-4u74-v389-98v3yv74z84",
      phone_number: "6175555310",
      request_fulfilled_at: "2024-10-19T02:20:00.000Z",
      requested_at: "2024-10-19T02:15:00.000Z",
      session_id: "4",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-18T23:30:10.012Z",
      hanger_id: "22",
      id: "fv2523y5-aa24-5v85-w490-09w4zw85a95",
      phone_number: "6175554199",
      request_fulfilled_at: "2024-10-19T02:35:00.000Z",
      requested_at: "2024-10-19T02:30:00.000Z",
      session_id: "4",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-19T00:00:21.345Z",
      hanger_id: "23",
      id: "fw3634z6-bb35-6w96-x501-10x5ax96b06",
      phone_number: "6175553088",
      request_fulfilled_at: "2024-10-19T02:50:00.000Z",
      requested_at: "2024-10-19T02:45:00.000Z",
      session_id: "4",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-19T00:30:32.678Z",
      hanger_id: "24",
      id: "fx4745a7-cc46-7x07-y612-21y6by07c17",
      phone_number: "6175551977",
      request_fulfilled_at: "2024-10-19T02:20:00.000Z",
      requested_at: "2024-10-19T02:15:00.000Z",
      session_id: "4",
      triaged_at: null,
    },
    {
      bar_id: "186898",
      checked_at: "2024-10-19T01:00:43.901Z",
      hanger_id: "25",
      id: "fy5856b8-dd57-8y18-z723-32z7cz18d28",
      phone_number: "6175550866",
      request_fulfilled_at: "2024-10-19T02:50:00.000Z",
      requested_at: "2024-10-19T02:45:00.000Z",
      session_id: "4",
      triaged_at: null,
    },
  ],
};

export const allSessionsCoats = {
  1: coatData,
  2: coatData,
  3: coatData,
  4: coatData,
};

export const demoUsers = [
  {
    username: "john.smith@example.com",
    role: "admin",
    barId: "186898",
  },
  {
    username: "sarah.jones@example.com",
    role: "manager",
    barId: "186898",
  },
  {
    username: "mike.wilson@example.com",
    role: "host",
    barId: "186898",
  },
  {
    username: "emily.brown@example.com",
    role: "host",
    barId: "186898",
  },
];

// Function to add a new session
export const addSession = (name) => {
  const newSession = {
    bar_id: 186898,
    completed_at: null,
    created_at: new Date().toISOString(),
    id: (sessionData[186898].length + 1).toString(),
    name: name,
    tags: [],
  };

  sessionData[186898].push(newSession);
  return newSession;
};
