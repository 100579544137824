import React, { useState, useEffect, useRef, useMemo } from "react";
import { socket } from "../utils/socket/socket";
import styled from "styled-components";
import { AiOutlineRight, AiOutlineMergeCells } from "react-icons/ai";
import { GrCoatCheck } from "react-icons/gr";
import NewModal from "./NewCoatModal";
import {
  endSession,
  getCoatsBySessionId,
  getSessionById,
  updateFulfilledAtTimestamp,
  updateTriagedAtTimestamp,
} from "../utils/api/api-consumer";
import { determineCoatStatus } from "../utils/helpers/helpers";
import {
  PageContainer,
  Header,
  Divider,
  ButtonGroup,
  Button,
  InsideButton,
} from "./common/common-components";
import BaseModal from "./common/BaseModal";
import { useNavigate } from "react-router";
import AlertBanner from "./common/AlertBanner";

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 0px 50px;
  height: 70%; // Ensure the container takes full height
`;

const Panel = styled.div`
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  border: 1px solid #ccc;
  height: calc(
    100vh - 150px
  ); // Adjust this value based on your header/footer height
  overflow-y: auto; // Make the panel scrollable
`;

const PanelHeaderContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  position: sticky; // Make the panel header sticky
  top: 0; // Stick to the top of the panel
  background-color: #fff; // Set background color to prevent overlap
  z-index: 1; // Ensure it stays above other content
`;

const PanelHeader = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #a1a1a1;
`;

const SearchInput = styled.input`
  width: 95px;
  font-size: 8px;
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #515151;
`;

const TableRow = styled.tr`
  border-top: 1px solid #eee;
  transition: height 0.3s ease, opacity 0.3s ease; // Transition for height and opacity

  &.slide-up {
    height: 0; // Collapse the height
    opacity: 0; // Fade out effect
    overflow: hidden; // Prevent content overflow
  }
`;

const TableHeaderContainer = styled.tr`
  width: 100%;
  box-shadow: inset 0 1px 0 #ccc, inset 0 -1px 0 #ccc; // Create inner top and bottom borders
  background-color: #f0f0f0;
  position: sticky; // Make the table header sticky
  top: ${(props) => props.headerHeight}px; // Use dynamic height
  background-color: #f0f0f0; // Set background color to prevent overlap
  z-index: 2; // Ensure it stays above other content
`;

const TableHeader = styled.th`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
`;

const TableData = styled.td`
  padding: 18px 10px;
  font-size: 14px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: #a1a1a1;
  border: none;
  cursor: pointer;
`;

const NoDataTableContainer = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  color: #a1a1a1;
  font-size: 14px;
`;

// React Component
const SessionPage = () => {
  const [requested, setRequested] = useState([]);
  const [waiting, setWaiting] = useState([]);
  const panelHeaderRef = useRef(null); // Create a ref for the PanelHeader
  const [headerHeight, setHeaderHeight] = useState(0); // State to store header height
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEndModalOpen, setIsEndModalOpen] = useState(false);
  const [allCoats, setAllCoats] = useState({});
  const [requestedSearchQuery, setRequestedSearchQuery] = useState("");
  const [waitingSearchQuery, setWaitingSearchQuery] = useState("");
  const [session, setSession] = useState({});
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const [showAlert, setShowAlert] = useState(false); // State for alert visibility
  const [alertType, setAlertType] = useState(false); // State for alert visibility

  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const filteredRequested = useMemo(() => {
    return requested.filter((coat) => {
      return (
        coat.hangerId.includes(requestedSearchQuery) ||
        coat.last4.includes(requestedSearchQuery)
      );
    });
  }, [requestedSearchQuery, requested]);

  const filteredWaiting = useMemo(() => {
    return waiting.filter((coat) => {
      return (
        coat.hangerId.includes(waitingSearchQuery) ||
        coat.last4.includes(waitingSearchQuery)
      );
    });
  }, [waitingSearchQuery, waiting]);

  useEffect(() => {
    if (!query.get("sessionId")) {
      navigate("/sessions");
    }
  }, [navigate, query]);

  useEffect(() => {
    socket.connect("/");

    socket.emit("join", { sessionId: query.get("sessionId") });
    // socket.emit("join", { barId: query.get("id") });

    return () => {
      socket.disconnect();
    };
  }, [query]);

  useEffect(() => {
    const fetchSessions = async () => {
      const session = await getSessionById(query.get("sessionId"));
      if (!session) {
        return;
      }
      setSession(session[0]);
    };

    fetchSessions();
  }, [query]);

  socket.on("newRequest", (arg) => {
    const { hangerId, phoneNumber } = arg;
    if (!requested.includes(hangerId)) {
      setAllCoats((prev) => {
        const updatedCoats = prev.requested.filter(
          (item) => item.hangerId !== hangerId
        );
        updatedCoats.push({ hangerId, last4: phoneNumber.slice(-4) });
        return {
          ...prev,
          requested: updatedCoats,
        };
      });
      setRequested([...requested, { hangerId, last4: phoneNumber.slice(-4) }]);
      console.log(`added hanger: ${hangerId} to the coats array`);
    } else {
      console.log(
        `Hanger: ${hangerId} is already in requested. Skipping add...`
      );
    }
  });

  useEffect(() => {
    (async function () {
      try {
        // in form [{ }]
        const response = await getCoatsBySessionId(query.get("sessionId"));

        const coatsByStatus = determineCoatStatus(response);
        setAllCoats(coatsByStatus);
        setRequested(coatsByStatus.requested);
        setWaiting(coatsByStatus.retrieved);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [query]);

  useEffect(() => {
    if (panelHeaderRef.current) {
      setHeaderHeight(panelHeaderRef.current.offsetHeight); // Set height based on ref
    }
  }, [panelHeaderRef]);

  const handleMarkRetrieved = async (hangerId) => {
    await updateTriagedAtTimestamp(query.get("sessionId"), hangerId);

    const updatedRequested = requested.filter(
      (item) => item.hangerId !== hangerId
    );
    const retrievedItem = requested.find((item) => item.hangerId === hangerId);

    setAllCoats((prev) => {
      return {
        ...prev,
        requested: updatedRequested,
        retrieved: [...prev.retrieved, retrievedItem],
      };
    });

    // Set the state to trigger the animation
    setRequested((prev) =>
      prev.map((item) =>
        item.hangerId === hangerId ? { ...item, isAnimating: true } : item
      )
    );

    setAlertMessage(`Triaged coat ${hangerId}`);
    setAlertType(`success`);
    setShowAlert(true);

    // Use a timeout to remove the item after the animation
    setTimeout(() => {
      setRequested(updatedRequested);
      setWaiting((prev) => [...prev, retrievedItem]);
    }, 300); // Match this duration with the CSS transition duration
  };

  const handleMarkFulfilled = async (hangerId) => {
    await updateFulfilledAtTimestamp(query.get("sessionId"), hangerId);

    const updatedRetrieved = waiting.filter(
      (item) => item.hangerId !== hangerId
    );

    setAllCoats((prev) => {
      return {
        ...prev,
        retrieved: updatedRetrieved,
        fulfilled: [
          ...prev.fulfilled,
          waiting.find((item) => item.hangerId === hangerId),
        ],
      };
    });

    // Set the state to trigger the animation
    setWaiting((prev) =>
      prev.map((item) =>
        item.hangerId === hangerId ? { ...item, isAnimating: true } : item
      )
    );

    setAlertMessage(`Fulfilled coat ${hangerId}`);
    setAlertType(`success`);
    setShowAlert(true);

    // Use a timeout to remove the item after the animation
    setTimeout(() => {
      setWaiting(updatedRetrieved);
    }, 300); // Match this duration with the CSS transition duration
  };

  const handleEndSession = async () => {
    await endSession(query.get("sessionId"));

    navigate(`/sessions`);
  };

  return (
    <>
      {isModalOpen ? (
        <NewModal onClose={setIsModalOpen} allCoats={allCoats} />
      ) : null}
      {isEndModalOpen && (
        <BaseModal
          onClose={setIsEndModalOpen}
          type={"text"}
          proceedType={"End"}
          proceedMethod={handleEndSession}
          title={"End Session"}
          copy="Are you sure you want to end your session?"
        />
      )}
      {showAlert && (
        <AlertBanner
          message={alertMessage}
          type={alertType}
          duration={2000}
          onClose={() => setShowAlert(false)}
        />
      )}
      <PageContainer>
        <Header>
          <span style={{ color: "#656565" }}>{session?.name}</span>
          <ButtonGroup>
            <Button onClick={() => setIsModalOpen(true)}>
              <InsideButton>
                <GrCoatCheck
                  style={{ marginRight: "8px", height: "12px", width: "12px" }}
                />
                All Coats
              </InsideButton>
            </Button>
            <Button primary onClick={() => setIsEndModalOpen(true)}>
              <InsideButton>
                <AiOutlineMergeCells style={{ marginRight: "8px" }} />
                End Session
              </InsideButton>
            </Button>
          </ButtonGroup>
        </Header>

        <Divider style={{ margin: "20px 50px 30px" }} />

        <Container>
          <Panel>
            <PanelHeaderContainer ref={panelHeaderRef}>
              <PanelHeader>{filteredRequested.length} REQUESTED</PanelHeader>
              <SearchInput
                type="text"
                placeholder="Search..."
                value={requestedSearchQuery}
                onChange={(e) => setRequestedSearchQuery(e.target.value)}
              />
            </PanelHeaderContainer>
            <Table>
              <thead>
                <TableHeaderContainer headerHeight={headerHeight}>
                  <TableHeader style={{ width: "30px" }}></TableHeader>
                  <TableHeader style={{ width: "100px" }}>Tag #</TableHeader>
                  <TableHeader style={{ width: "120px" }}>
                    Last 4 Digits
                  </TableHeader>
                  <TableHeader style={{ width: "150px" }}>Actions</TableHeader>
                </TableHeaderContainer>
              </thead>
              <tbody>
                {filteredRequested.map((row) => {
                  return (
                    <TableRow
                      className={row.isAnimating ? "slide-up" : ""}
                      key={row.hangerId}
                    >
                      <TableData>
                        <input type="checkbox" />
                      </TableData>
                      <TableData>{row.hangerId}</TableData>
                      <TableData>{row.last4}</TableData>
                      <TableData
                        onClick={() => handleMarkRetrieved(row.hangerId)}
                      >
                        <ActionButtonContainer>
                          <ActionButton>Mark retrieved</ActionButton>
                          <AiOutlineRight style={{ color: "#a1a1a1" }} />
                        </ActionButtonContainer>
                      </TableData>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
            {!filteredRequested.length && (
              <NoDataTableContainer>
                <GrCoatCheck />
                No Requests
              </NoDataTableContainer>
            )}
          </Panel>

          <Panel>
            <PanelHeaderContainer>
              <PanelHeader>{filteredWaiting.length} WAITING</PanelHeader>
              <SearchInput
                type="text"
                placeholder="Search..."
                value={waitingSearchQuery}
                onChange={(e) => setWaitingSearchQuery(e.target.value)}
              />
            </PanelHeaderContainer>
            <Table>
              <thead>
                <TableHeaderContainer headerHeight={headerHeight}>
                  <TableHeader style={{ width: "30px" }}></TableHeader>
                  <TableHeader style={{ width: "100px" }}>Tag #</TableHeader>
                  <TableHeader style={{ width: "120px" }}>
                    Last 4 Digits
                  </TableHeader>
                  <TableHeader style={{ width: "150px" }}>Actions</TableHeader>
                </TableHeaderContainer>
              </thead>
              <tbody>
                {filteredWaiting.map((row) => {
                  return (
                    <TableRow
                      className={row.isAnimating ? "slide-up" : ""}
                      key={row.hangerId}
                    >
                      <TableData>
                        <input type="checkbox" />
                      </TableData>
                      <TableData>{row.hangerId}</TableData>
                      <TableData>{row.last4}</TableData>
                      <TableData
                        onClick={() => handleMarkFulfilled(row.hangerId)}
                      >
                        <ActionButtonContainer>
                          <ActionButton>Mark fulfilled</ActionButton>
                          <AiOutlineRight style={{ color: "#a1a1a1" }} />
                        </ActionButtonContainer>
                      </TableData>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
            {!filteredWaiting.length && (
              <NoDataTableContainer>
                <GrCoatCheck />
                No Waiting
              </NoDataTableContainer>
            )}
          </Panel>
        </Container>
      </PageContainer>
    </>
  );
};

export default SessionPage;
