import React, { useState, useEffect, useRef } from "react";
import TagFilter from "../TagFilter";
import TagData from "../TagData";
import DatePicker from "../DatePicker";
import styled from "styled-components";
import { AiOutlineRight } from "react-icons/ai";
import { GrCoatCheck } from "react-icons/gr"; // Add this import at the top
import { Spinner } from "./common-components"; // Add this import

const SessionTableContainer = styled.div`
  background-color: #fff;
  width: 60vw;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin: auto;
  // overflow: auto;
  margin-bottom: 50px;
  height: calc(
    100vh - 150px
  ); // Adjust this value based on your header/footer height
`;

const FullTableHeader = styled.div`
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  z-index: 100;
`;

const TopTableHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  background-color: #fff;
  z-index: 1000;
  border-radius: 10px;
`;

const Title = styled.h3`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
`;

const RightHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
`;

const TableContainer = styled.div`
  overflow-y: scroll;
  position: relative;

  // TODO: Leave this for now, does anyone else expierence bad styles?
  // /* Webkit browsers (Chrome, Safari) */
  // &::-webkit-scrollbar {
  //   margin-right: 10px;
  //   width: 8px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: transparent;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: #d1d1d1;
  //   border-radius: 4px;
  // }

  // /* For the bottom right corner */
  // &::-webkit-scrollbar-corner {
  //   border-bottom-right-radius: 3px;
  //   background-color: #fff;
  // }

  // /* Firefox */
  // scrollbar-color: #d1d1d1;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #515151;
  max-height: 80%;
`;

const TableRow = styled.tr`
  border-bottom: ${(props) => (props.isLastRow ? "none" : "1px solid #eee")};
  position: relative;
`;

const TableHeaderContainer = styled.div`
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // position: sticky; // Make the table header sticky
  // top: ${(props) => props.headerHeight}px; // Use dynamic height
`;

const TableHeader = styled.div`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  width: 100px; // Set a fixed width for the header
`;

const TableData = styled.td`
  padding: 20px 10px;
  font-size: 14px;
  width: 100px; // Set a fixed width for the data cells
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: #a1a1a1;
  border: none;
  cursor: pointer;

  &:hover {
    color: #515151;
    transition: ease-in-out 0.3s;
  }
`;

const ClearFiltersButton = styled.div`
  background-color: white;
  color: #a1a1a1;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 10px;

  &:hover {
    color: #515151;
    transition: ease-in-out 0.3s;
  }
`;

const NoDataTableContainer = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  color: #a1a1a1;
  font-size: 14px;
`;

const LoadingContainer = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * CheckItTable Component
 *
 * A reusable table component for displaying coat check data with filtering capabilities.
 *
 * Props:
 * @param {Object} sessionInfo - Contains coat check session data including coatData array
 * @param {Object} columnNames - Maps data fields to display names for table columns
 * @param {string} title - Title displayed above the table
 * @param {boolean} tags - Whether to show tag filtering (default: true)
 * @param {boolean} date - Whether to show date filtering (default: true)
 * @param {number} rows - Number of rows to display (default: 5)
 */

const CheckItTable = ({
  tableInfo,
  columnNames,
  columnRenderers = {},
  title,
  tags = true,
  date = true,
  actions = true,
  rows = 5,
  selectedTags,
  setSelectedTags,
  isLoading = false,
}) => {
  const [sessionSelectedTags, setSessionSelectedTags] = useState({});
  const [headerHeight, setHeaderHeight] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const totalCoats = tableInfo?.length;
  const tableRef = useRef(null);
  const panelHeaderRef = useRef(null); // Create a ref for the PanelHeader

  useEffect(() => {
    if (panelHeaderRef.current) {
      setHeaderHeight(panelHeaderRef.current.offsetHeight); // Set height based on ref
    }
  }, [panelHeaderRef]);

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    if (panelHeaderRef.current) {
      setHeaderHeight(panelHeaderRef.current.offsetHeight); // Set height based on ref
    }
  }, [panelHeaderRef]);

  return (
    <SessionTableContainer>
      <FullTableHeader ref={panelHeaderRef}>
        <TopTableHeader>
          <Title>
            {totalCoats} {title.toUpperCase()}
          </Title>
          <RightHeaderContainer>
            {(selectedTags?.length || (startDate && endDate)) && (
              <ClearFiltersButton onClick={handleClearFilters}>
                Clear filters
              </ClearFiltersButton>
            )}
            {Array.isArray(tags) && (
              <TagFilter
                selectedItem={selectedTags}
                setSelectedItem={setSelectedTags}
                tags={tags}
              />
            )}
            {date && (
              <DatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            )}
          </RightHeaderContainer>
        </TopTableHeader>
        <TableHeaderContainer>
          {Object.values(columnNames).map((displayName, index) => (
            <TableHeader key={index}>{displayName}</TableHeader>
          ))}
          {tags && <TableHeader>Tags</TableHeader>}
          {actions && <TableHeader>Actions</TableHeader>}
        </TableHeaderContainer>
      </FullTableHeader>

      {isLoading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : !tableInfo?.length ? (
        <NoDataTableContainer>
          <GrCoatCheck />
          No Tags
        </NoDataTableContainer>
      ) : (
        <TableContainer
          headerHeight={headerHeight}
          ref={tableRef}
          style={{
            maxHeight: "calc(100% - 95px)",
            borderBottomRightRadius: "10px",
          }}
        >
          <Table>
            <tbody>
              {tableInfo.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    isLastRow={index === tableInfo.length - 1}
                  >
                    {Object.keys(columnNames).map((key) => (
                      <TableData
                        key={key}
                        style={{ padding: tags ? "0px 10px" : undefined }}
                      >
                        {columnRenderers[key]
                          ? columnRenderers[key](item[key])
                          : item[key]}
                      </TableData>
                    ))}
                    {tags && (
                      <TableData>
                        <TagData
                          sessionId={item.sessionId}
                          selectedItem={sessionSelectedTags}
                          setSelectedItem={setSessionSelectedTags}
                          tags={item.tags}
                        />
                      </TableData>
                    )}
                    {actions && (
                      <TableData>
                        <ActionButtonContainer>
                          <ActionButton>View insights</ActionButton>
                          <AiOutlineRight style={{ color: "#a1a1a1" }} />
                        </ActionButtonContainer>
                      </TableData>
                    )}
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </SessionTableContainer>
  );
};

export default CheckItTable;
