export const hangerIdFormatter = (hid) => {
  const splitId = hid.split(" ");
  return `${splitId[0]}-${splitId[2]}`;
};

export const determineCoatStatus = (coats) => {
  const statuses = {
    fulfilled: [],
    retrieved: [],
    requested: [],
    checked: [],
  };

  coats.forEach((coat) => {
    const formattedCoat = {
      hangerId: coat.hanger_id,
      last4: coat.phone_number.slice(-4),
    };
    if (coat.request_fulfilled_at) {
      statuses.fulfilled.push(formattedCoat);
    } else if (coat.triaged_at) {
      statuses.retrieved.push(formattedCoat);
    } else if (coat.requested_at) {
      statuses.requested.push(formattedCoat);
    } else {
      statuses.checked.push(formattedCoat);
    }
  });

  return statuses;
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string") {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatPhoneNumber = (phoneNumber, countryCode) => {
  if (countryCode.name === "US" || countryCode.name === "CA") {
    let noHyphen = phoneNumber.split("-").join("");
    if (noHyphen.length > 10) {
      noHyphen = noHyphen.substring(0, 10);
    }
    if (noHyphen.length <= 3) {
      return noHyphen;
    } else if (noHyphen.length <= 6) {
      return noHyphen.replace(/(\d{3})(\d+)/, "$1-$2");
    } else if (noHyphen.length >= 7) {
      return noHyphen.replace(/(\d{3})(\d{3})(\d)/, "$1-$2-$3");
    }
  } else if (countryCode.name === "UK") {
    let noSpace = phoneNumber.split(" ").join("");
    if (noSpace.length > 10) {
      noSpace = noSpace.substring(0, 10);
    }
    if (noSpace.length <= 4) {
      return noSpace;
    } else if (noSpace.length <= 10) {
      return noSpace.replace(/(\d{4})(\d+)/, "$1 $2");
    }
  }
  return phoneNumber; // Return unformatted if no conditions met
};

export const processCoatData = (sessionData) => {
  if (!Object.values(sessionData)?.flat().length) {
    return;
  }
  const halfHourlyData = {};

  // Helper function to convert 24h to 12h format
  const formatTime = (hour, min) => {
    const period = hour >= 12 ? "PM" : "AM";
    const displayHour = hour > 12 ? hour - 12 : hour;
    return `${displayHour}:${min === 0 ? "00" : min}${period}`;
  };

  const flattenedSessionData = Object.values(sessionData)?.flat();
  let minCheckedAt =
    new Date(
      Math.min(
        ...flattenedSessionData.map((data) => {
          // Use the full timestamp instead of just the adjusted hour
          return new Date(data.checked_at).getTime(); // Get the full timestamp in milliseconds
        })
      )
    ).getHours() - 4;

  let maxRequestFulfilledAt =
    new Date(
      Math.max(
        ...flattenedSessionData.map((data) => {
          // Use the full timestamp instead of just the adjusted hour
          return new Date(data.request_fulfilled_at).getTime(); // Get the full timestamp in milliseconds
        })
      )
    ).getHours() - 4;

  if (minCheckedAt < 0) {
    minCheckedAt += 24;
  }

  if (maxRequestFulfilledAt < 0) {
    maxRequestFulfilledAt += 24;
  }

  let didTheReverse = false;
  if (maxRequestFulfilledAt < minCheckedAt) {
    maxRequestFulfilledAt += 24;
    didTheReverse = true;
  }

  // Initialize half-hours from 18:00 to 23:30
  for (let hour = minCheckedAt; hour <= maxRequestFulfilledAt; hour++) {
    for (let min = 0; min <= 30; min += 30) {
      const timeKey = `${hour}:${min === 0 ? "00" : "30"}`;
      halfHourlyData[timeKey] = {
        hour: formatTime(hour, min), // Format the display time
        checkIns: 0,
        checkOuts: 0,
      };
    }
  }

  // Count check-ins and check-outs for each half hour
  for (const coatData of Object.values(sessionData)) {
    coatData.forEach((coat) => {
      const arrivalTime = new Date(coat.checked_at);
      let arrivalHour = arrivalTime.getHours() - 4;
      const arrivalMin = arrivalTime.getMinutes();

      const departureTime = new Date(coat.request_fulfilled_at);
      let departureHour = departureTime.getHours() - 4;
      const departureMin = departureTime.getMinutes();

      if (arrivalHour < 0) {
        arrivalHour += 24;
      }

      if (departureHour < 0) {
        departureHour += 24;
      }

      // Round to nearest half hour
      const arrivalHalfHour = parseInt(arrivalMin) < 30 ? "00" : "30";
      const departureHalfHour = parseInt(departureMin) < 30 ? "00" : "30";

      if (didTheReverse && arrivalHour < 8) {
        arrivalHour += 24;
      }
      if (didTheReverse && departureHour < 8) {
        departureHour += 24;
      }

      const arrivalKey = `${parseInt(arrivalHour)}:${arrivalHalfHour}`;
      const departureKey = `${parseInt(departureHour)}:${departureHalfHour}`;

      if (halfHourlyData[arrivalKey]) halfHourlyData[arrivalKey].checkIns += 1;
      if (halfHourlyData[departureKey])
        halfHourlyData[departureKey].checkOuts += 1;
    });
  }

  return Object.values(halfHourlyData);
};

export const processRevenue = (sessionData) => {
  if (!sessionData) {
    return 0;
  }

  const coatData = Object.values(sessionData);
  return coatData.map((session) => {
    return {
      totalRevenue: session.length * 5,
      date: new Date(session[0]?.checked_at).toISOString(),
    };
  });
};

export const getTotalVisitors = (sessionData) => {
  if (!sessionData) {
    return 0;
  }

  return Object.values(sessionData).reduce(
    (acc, currArray) => acc + currArray.length,
    0
  );
};

export const getDistinctVisitors = (sessionData) => {
  if (!sessionData) {
    return 0;
  }

  return Object.values(sessionData).reduce(
    (acc, currArray) =>
      acc + currArray.filter((item) => item.has_checked_before).length,
    0
  );
};

export const calculateAverageTimes = (sessionData) => {
  if (!sessionData) {
    return 0;
  }
  let totalArrivalTime = 0;
  let totalDepartureTime = 0;
  let totalStayLength = 0;
  let arrivalCount = 0;
  let departureCount = 0;

  Object.values(sessionData).forEach((coatData) => {
    coatData.forEach((coat) => {
      let arrivalTime =
        new Date(coat.checked_at).getHours() * 60 +
        new Date(coat.checked_at).getMinutes() -
        240;
      let departureTime =
        new Date(coat.request_fulfilled_at).getHours() * 60 +
        new Date(coat.request_fulfilled_at).getMinutes() -
        240;

      // If either time is less than 8am, add a full days worth to account for the crossover
      if (arrivalTime < 480) {
        arrivalTime += 1440;
      }

      if (departureTime < 480) {
        departureTime += 1440;
      }

      if (arrivalTime) {
        totalArrivalTime += arrivalTime;
        arrivalCount++;
      }

      if (departureTime) {
        totalDepartureTime += departureTime;
        departureCount++;
      }

      if (arrivalTime && departureTime) {
        totalStayLength += departureTime - arrivalTime;
      }
    });
  });

  const avgAr = totalArrivalTime / arrivalCount;
  const hours = Math.floor((avgAr / 60) % 24);
  const minutes = Math.floor(avgAr % 60);
  const period = hours >= 12 ? "PM" : "AM";
  const displayHour = hours > 12 ? hours - 12 : hours;
  const averageArrivalTime = `${displayHour}:${minutes
    .toString()
    .padStart(2, "0")}${period}`;

  const avgDp = totalDepartureTime / departureCount;
  const hoursDp = Math.floor((avgDp / 60) % 24);
  const minutesDp = Math.floor(avgDp % 60);
  const periodDp = hoursDp >= 12 ? "PM" : "AM";
  const displayHourDp = hoursDp > 12 ? hoursDp - 12 : hoursDp;
  const averageDepartureTime = `${displayHourDp}:${minutesDp
    .toString()
    .padStart(2, "0")}${periodDp}`;

  const avgSt = totalStayLength / departureCount;
  const hoursSt = Math.floor((avgSt / 60) % 24);
  const minutesSt = Math.floor(avgSt % 60);
  const averageStayLength = `${hoursSt}h:${minutesSt
    .toString()
    .padStart(2, "0")}`;

  return {
    averageArrivalTime,
    averageDepartureTime,
    delta: averageStayLength,
  };
};
